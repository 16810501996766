import {
    Abstract
} from './Abstract';

export class InvoiceConceptTaxesTransferred extends Abstract {
    constructor(session) {
        super('invoice/InvoiceManager', session);
        this.id_invoice_concept = null;
        this.base = null;
        this.tax = null;
        this.factor_type = null;
        this.rate_or_fee = null;
        this.amount = null;
    }
}
