import {
    Abstract
} from './Abstract';

export class AccountingPolicyDetail extends Abstract {
    constructor(session) {
        super('accounting/AccountingPolicyDetail', session);
        this.id_accounting_policy = null;
        this.id_number = 1;
        this.id_accounting_ledger_account = null;
        this.id_accounting_cost_center = null;
        this.key_id_accounting_ledger_account = null;
        this.accounting_index = null;
        this.accounting_charge = 0.00;
        this.accounting_payment = 0.00;
        this.concept = null;
        this.name = null;
    }
}