import {
    Abstract
} from './Abstract';

import axios from 'axios';

export class EmailConfiguration extends Abstract {
    constructor(session) {
        super('general/EmailConfiguration', session);
        this.key_name = null;
        this.type = 'SENDER';
        this.email_address = null;
        this.email_password = null;
        this.sender_title = null;
        this.sender_cc = null;
        this.email_host = null;
        this.email_port = null;
        this.encryption_type = 'NONE';
    }

    async send(body, document) {
        let response = await axios.post(this.controller + "/send/" + document, body, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        })
        return response.data;
    }

    async retrieveByKeyName() {
        if (this.key_name == 0 || this.key_name == "" || this.key_name == null)
            throw "ERROR. Ingrese un key_name para encontrar el registro"
        let response = await axios.get(this.controller + "/keyname/" + this.key_name, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }
}