import {
    Abstract
} from './Abstract';

export class CreditNoteConceptTaxesTransferred extends Abstract {
    constructor(session) {
        super('credit_note/CreditNoteManager', session);
        this.id_credit_note_concept = null;
        this.base = null;
        this.tax = null;
        this.factor_type = null;
        this.rate_or_fee = null;
        this.amount = null;
    }
}
