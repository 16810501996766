<template>
  <div class="grid crud-demo">
    <Loader v-model="loading" />
    <Helper v-model="policyHelper" @selected="selectPolicy" header="Buscar Poliza Contable" :headers="policyHeaders" :rows="entities" />
    <div class="col-12">
      <div class="card">
        <Panel header="Poliza Contable">
                  <Toast />
        <BasicFormToolbar
        @new="newPolicy" @cancel="cancelar" @save="save" @export="exportCSV"
        :actions="entity.id ? ['new','cancel','save'] :  ['new','save']" />
        <div class="grid">
          <div class="col-12">
                <Fieldset legend="Informacion General">
                  <div class="p-fluid formgrid grid">
                    <FormInputText :disabled="true" wrapperClass="field col-2" label="Folio" v-model="entity.id_number" :search="true" @search="policyHelper.visible = true"  />
                    <FormDropdown wrapperClass="field col-2" label="Tipo" 
                    v-model="entity.id_accounting_policy_type" :valid="validate.validations.id_accounting_policy_type" :options="types" optionLabel="name" optionValue="id"/>
                    <Status :image="status.image" :severity="status.severity" :status="status.status" />
                    <FormCalendar wrapperClass="field col-2" label="Fecha" 
                      v-model="entity.date" :valid="validate.validations.date"/>
                    <FormCalendar :disabled="true" wrapperClass="field col-2" label="Fecha Alta" 
                      v-model="entity.created" />
                    <FormInputText :disabled="true" wrapperClass="field col-2" label="Usuario" 
                    v-model="entity.created_by"/>
                    <div class="field col-2">
                        <label for="sat">Moneda</label>
                        <Dropdown  :filter="true" v-model="entity.currency" :options="c_Moneda" optionLabel="c_Moneda" optionValue="c_Moneda" 
                         :class="{ 'p-invalid': validate.validations.currency === false }">
                            <template #option="slotProps">
                                <div>
                                    <div>{{ slotProps.option.c_Moneda }} - {{ slotProps.option.Descripcion.toUpperCase() }}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <small class="p-invalid" v-if="validate.validations.currency === false"> Favor de llenar el campo </small>
                    </div>
                    <FormInputNumber :disabled="!entity.currency || entity.currency == 'MXN'" wrapperClass="field col-2" label="Tasa de Cambio" 
                    v-model="entity.exchange_rate" :valid="validate.validations.exchange_rate" :minFractionDigits="2" :maxFractionDigits="4" mode="decimal"/>
                    <FormInputText wrapperClass="field col-8" label="Descripcion" 
                    v-model="entity.description" :valid="validate.validations.description"  :textArea="true"/>
                    <div class="field col-4 offset-8">
                      <span class="p-buttonset">
                        <Button @click="afectar" label="Afectar" icon="pi pi-check" />
                        <Button @click="desafectar" label="Desafectar" class="p-button-warning" icon="pi pi-ban" />
                      </span>
                    </div>                 
                  </div> 
                </Fieldset> <br>
                <Fieldset legend="Movimiento" :toggleable="true">
                  <BasicFormToolbar 
                  @new="newDetail" @save="saveDetail" @delete="deleteDetail"
                  :actions="['new','save','delete']" />
                  <div class="p-fluid formgrid grid">
                    <div class="field col-2">
                        <label>Cuenta</label>
                        <Dropdown ref="accountLedger" 
                        :filterFields="['id_key', 'name']" 
                        :filter="true" @change="selectLedger" 
                        v-model="detail.id_accounting_ledger_account" 
                        :options="accounts" optionLabel="id_key" optionValue="id"
                         :class="{ 'p-invalid': validateDetail.validations.id_accounting_ledger_account === false }">
                            <template #option="slotProps">
                                <div>
                                    <div>{{ slotProps.option.id_key }} - {{ slotProps.option.name.toUpperCase() }}</div>
                                </div>
                            </template>
                        </Dropdown>
                        <small class="p-invalid" v-if="validateDetail.validations.id_accounting_ledger_account === false"> Favor de llenar el campo </small>
                    </div>
                    <FormInputText wrapperClass="field col-3" label="Nombre Cuenta" 
                      v-model="detail.name"/>
                    <FormDropdown :showClear="true" wrapperClass="field col-2" label="Centro Costo" 
                    :options="centres" v-model="detail.id_accounting_cost_center" optionLabel="name" optionValue="id"/>
                    <FormInputNumber wrapperClass="field col-1" label="Cargo" 
                    v-model="detail.accounting_charge" :disabled="detail.accounting_payment > 0" :minFractionDigits="2" :maxFractionDigits="4" mode="decimal"/>
                    <FormInputNumber wrapperClass="field col-1" label="Abono" 
                    v-model="detail.accounting_payment" :disabled="detail.accounting_charge > 0"  :minFractionDigits="2" :maxFractionDigits="4" mode="decimal"/>
                    <FormInputText :valid="validateDetail.validations.concept" wrapperClass="field col-3" label="Concepto" 
                    v-model="detail.concept" @tab="saveDetail" :textArea="true"/>
                  </div> 
                </Fieldset> <br>
                <Fieldset :toggleable="true" legend="Detalle">
                   <BasicDatatable :exportFilename="'POLIZA_DETALLE'" dataKey="id_number" @selected="selectDetail" :selectionMode="'single'" :headers="headers" :rows="entity.details" />
                </Fieldset>
          </div>
        </div>
        <DeleteDialog v-model="deleteDialog" @closed="deleteDialog = false" @deleted="deleted" />
        </Panel>
      </div>
    </div>
  </div>
</template>

<script>
import { AccountingPolicyType } from "../../../models/contabilidad/AccountingPolicyType";
import { AccountingPolicy } from "../../../models/contabilidad/AccountingPolicy";
import { AccountingCostCenter } from "../../../models/contabilidad/AccountingCostCenter";
import { AccountingLedgerAccount } from "../../../models/contabilidad/AccountingLedgerAccount";
import { AccountingPolicyDetail } from "../../../models/contabilidad/AccountingPolicyDetail";

import { FilterMatchMode } from "primevue/api";
import {
  HeaderGrid,
  Rule,
  validate,
  fillObject,
  Toast,
  ErrorToast,
  satCatalogo,
} from "../../../utilities/General";
import Loader from "../../../components/general/Loader.vue";
import BasicFormToolbar from "../../../components/general/BasicFormToolbar.vue";
import DeleteDialog from "../../../components/general/DeleteDialog.vue";
import FormInputText from "../../../components/general/FormInputText.vue";
import FormDropdown from "../../../components/general/FormDropdown.vue";
import FormInputNumber from "../../../components/general/FormInputNumber.vue";
import FormCalendar from "../../../components/general/FormCalendar.vue";
import Status from "../../../components/general/Status.vue";

import BasicDatatable from "../../../components/general/BasicDatatable.vue";

import Session from "../../../mixins/sessionMixin";
import Helper from '../../../components/general/HelperDialog.vue';

export default {
  mixins: [Session],
  props: {
    id: null
  },
  data() {
    return {
      policyHelper: {
          visible: false,
      },
      entity: null,
      parent: null,
      detail: new AccountingPolicyDetail(this.session),
      c_Moneda: [],
      centres: [],
      accounts: [],
      types: [],
      uploadURL: null,
      newDialog: false,
      deleteDialog: false,
      filters: {},
      rules: [
        new Rule({ name: "id_accounting_policy_type" }),
        new Rule({ name: "date" }),
        new Rule({ name: "currency" }),
        new Rule({ name: "exchange_rate" }),
        new Rule({ name: "description" })
      ],
      policyHeaders: [
        new HeaderGrid("Año", "date", {type: 'year'}),
        new HeaderGrid("Mes", "date", {type: 'month'}),
        new HeaderGrid("Clave", "key_type"),
        new HeaderGrid("Folio", "id_number"),
        new HeaderGrid("Fecha", "date", { type: 'date' }),
        new HeaderGrid("Descripcion", "description"),
        new HeaderGrid("Cargo", "charges_total", { type: 'decimal' }),
        new HeaderGrid("Abono", "payment_total", { type: 'decimal' }),
      ],
      entities: [],
      validate: {
        valid: false,
        validations: {
          id_accounting_policy_type: null,
          date: null,
          currency: null,
          exchange_rate: null,
          description: null
        },
      },
      rulesDetail: [
        new Rule({ name: "id_accounting_ledger_account" }),
        new Rule({ name: "concept" })
      ],
      validateDetail: {
        valid: false,
        validations: {
          id_accounting_ledger_account: null,
          concept: null
        },
      },
      headers: [
        new HeaderGrid("#", "id_number"),
        new HeaderGrid("Cuenta", "account"),
        new HeaderGrid("Centro Costo", "accounting_cost_center"),
        new HeaderGrid("Concepto", "concept"),
        new HeaderGrid("Cargo", "accounting_charge", { function: "SUM", type: "decimal"}),
        new HeaderGrid("Abono", "accounting_payment", { function: "SUM", type: "decimal"}),
      ],
      loading: false,
    };
  },
  watch: {
    ["detail.accounting_charge"](newValue) {
      if(newValue > 0.00) 
        this.detail.accounting_payment = 0.00;
    },
    ["detail.accounting_payment"](newValue) {
      if(newValue > 0.00) 
        this.detail.accounting_charge = 0.00;
    },
    ["detail.id_accounting_ledger_account"](newValue) {
      console.log(newValue);
      console.log(this.detail.id_accounting_ledger_account);
       if(this.detail.id_accounting_ledger_account) {
         this.detail.name = this.accounts.find(x => x.id == newValue).name;
       }
    },
    ["entity.currency"](newValue) {
      if (newValue == "MXN") {
        this.entity.exchange_rate = "1.00"
      }
    }
  },
  computed: {
    status(){
      if (this.entity.status == 1) {
        return {
          severity: 'primary',
          status: 'Registrada',
          image: "processing.png"
        } 
      } else if (this.entity.status == 2) {
        return {
          severity: 'primary',
          status: 'Afectada',
          image: "afected.png"
        } 
      }
      else if (this.entity.status == 9) {
        return {
          severity: 'danger',
          status: 'Cancelada',
          image: "cancel.png"
        } 
      } else {
        return {
          severity: 'primary',
          status: 'Afectada',
          image: "afected.png"
        } 
      }
    }
  },
  components: { Helper, Status, FormCalendar, BasicDatatable,FormInputNumber,FormDropdown,Loader,BasicFormToolbar, DeleteDialog, FormInputText},
  created() {
    this.entity = new AccountingPolicy(this.session);
    this.initFilters();
  },
  async mounted() {
    this.c_Moneda = await satCatalogo(9);
    await this.refresh();
  },
  methods: {
    newPolicy() {
      this.entity = new AccountingPolicy(this.session);
    },
    async deleteDetail() {
      this.loading = true;
      try {
        console.log(this.entity.status);
         if (this.entity.status != 1) 
          throw "No se puede eliminar este detalle, la factura debe de estar en estatus 'REGISTRADA'";
        await new AccountingPolicy(this.session).deleteDetail(this.detail.id);
        //* Quitamos el nuestro
        this.entity.details = this.entities.filter((val) => val.id !== this.detail.id);
        this.entity.details.forEach(det => {
          if (det.id_number > this.detail.id_number) {
            det.id_number = det.id_number - 1;
          }
        });
        this.detail =  new AccountingPolicyDetail(this.session);
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    selectDetail(detail) {
      this.detail = fillObject(this.detail, {...detail});
      this.detail.edit = true;
    },
    saveDetail() {
      try {
        if (this.entity.status != 1) 
          throw "No se puede guardar el detalle, la factura debe de estar en estatus 'REGISTRADA'";
        this.validateDetail = validate(this.detail, this.rulesDetail);
        if (!this.validateDetail.valid) 
          throw "Favor de validar los campos";
        if ((this.detail.accounting_charge == 0 || !this.detail.accounting_charge) 
        && (this.detail.accounting_payment == 0 || !this.detail.accounting_payment)) {
          throw "Debe de agregar algun valor a 'cargo' o 'abono'"
        }

        //* GUARDADO
        if (!this.detail.edit) {
          this.detail.id_number = (this.entity.details.length + 1);
          this.detail.key_id_accounting_ledger_account = this.accounts.find(x => x.id == this.detail.id_accounting_ledger_account).id_key;
          this.detail.account = this.detail.key_id_accounting_ledger_account + ' ' + this.accounts.find(x => x.id == this.detail.id_accounting_ledger_account).name;
          if (this.detail.id_accounting_cost_center) 
            this.detail.accounting_cost_center = this.centres.find(x => x.id == this.detail.id_accounting_cost_center).name;
          else
            this.detail.accounting_cost_center = null;
          this.entity.details.push({
            ...this.detail
          });
          this.detail = new AccountingPolicyDetail(this.session);
        }else {
          //* EDITAR
          this.detail.key_id_accounting_ledger_account = this.accounts.find(x => x.id == this.detail.id_accounting_ledger_account).id_key;
          this.detail.account = this.detail.key_id_accounting_ledger_account + ' ' + this.accounts.find(x => x.id == this.detail.id_accounting_ledger_account).name;
          if (this.detail.id_accounting_cost_center) 
            this.detail.accounting_cost_center = this.centres.find(x => x.id == this.detail.id_accounting_cost_center).name;
          else
            this.detail.accounting_cost_center = null;
          delete this.detail.edit;
          
          let index = this.entity.details.findIndex(x => x.id_number == this.detail.id_number);
          this.entity.details[index] = {
            ...this.detail
          };
          this.detail = new AccountingPolicyDetail(this.session);
        }
        //this.$refs.accountLedger.$el.focus();
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      }
      
    },
    deleted() {
      this.deleteEntity();
    },
    newDetail() {
      this.detail = new AccountingPolicyDetail(this.session);
    },
    hideDialog() {
      this.newDialog = false;
    },
    async selectPolicy(policy) {
      this.loading = true;
      try {
        this.entity = fillObject(this.entity, policy);
        this.entity.created = new Date(this.entity.created);
        this.entity.date = new Date(this.entity.date);
        this.entity.details = await this.entity.getDetails();
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    async save() {
          try {
            //* Validacion de form
            this.loading = true;
            this.validate = validate(this.entity, this.rules);
            if (!this.validate.valid) {
            throw "Favor de validar los campos";
            }              
            //* Si el id es != 0 entonces actualizamos
            if (this.entity.id && this.entity.id > 0) {
              //* Actualizamos
              let entity = await this.entity.update();
              this.$toast.add({
                severity: "success",
                summary: "Actualizar",
                detail: "Informacion actualizada con exito",
                life: 3000,
              });
              this.$emit('update', entity);
              //* Modificamos el listado pah
              let index = this.entities.findIndex((x) => x.id == this.entity.id);
              this.entities[index] = entity;
            }
            else {
              //* Creamos uno nuevo
              let entity = await this.entity.save();
              //* Agregamos un dato extra

              this.entities.push(entity);
              this.$toast.add(
                new Toast({
                  summary: "Creacion",
                  detail: "Informacion guardada con exito",
                })
              );
            }
            this.entity = new AccountingPolicy(this.session);
            if (this.open == true){
              this.entities = await this.entity.all();
            }
          } catch (error) {
            this.$toast.add(new ErrorToast(error));
          } finally {
            this.loading = false;
          }
        },
    edit(entity) {
      this.entity = fillObject(this.entity, entity);
      this.newDialog = true;
    },
    selectLedger(e) {
      let account = this.accounts.find(x => x.id == e.value);
      let children = this.accounts.filter(x => x.id_accounting_ledger_account_parent == account.id);
      if (children.length > 0) {
        this.detail.id_accounting_ledger_account = null;
        this.detail.name = null;
        this.$toast.add(new ErrorToast("No se puede utilizar esta cuenta, ya que no es capturable"));
      }
    },
    async afectar() {
      this.loading = true;
      try {
        if (this.entity.status == 9 || this.entity.status == 2) 
          throw "La poliza debe estar en estatus 'REGISTRADA' para realizar esta accion"
        await this.entity.afectar();
        this.entity.status = 2;
        this.entities.find(x => x.id == this.entity.id).status = 2;
        this.$toast.add(
          new Toast({
            summary: "Poliza",
            detail: "Poliza afectada con exito",
          })
        );
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    async desafectar() {
      this.loading = true;
      try {
        if (this.entity.status == 9 || this.entity.status == 1) 
          throw "La poliza debe estar en estatus 'AFECTADA' para realizar esta accion"
        
        await this.entity.desafectar();
        this.entities.find(x => x.id == this.entity.id).status = 1;
        this.entity.status = 1;
        this.$toast.add(
          new Toast({
            summary: "Poliza",
            detail: "Poliza desafectada con exito",
          })
        );
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    async cancelar() {
      this.loading = true;
      try {
        if (this.entity.status == 9 || this.entity.status == 2) 
          throw "La poliza debe estar en estatus 'REGISTRADA' para realizar esta accion"
        await this.entity.cancelar();
        this.entity.status = 9;
        this.entities.find(x => x.id == this.entity.id).status = 9;
        this.$toast.add(
          new Toast({
            summary: "Poliza",
            detail: "Poliza cancelada con exito",
          })
        );
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    async deleteEntity() {
      try {
        this.loading = true;
        //* Eliminamos de la base
        await this.entity.delete();
        //* Eliminamos de la vista
        this.entities = this.entities.filter((val) => val.id !== this.entity.id);
        this.deleteDialog = false;
        //* Reordenamos
        let nodes = this.entities.map(x => {
        return {
            key: x.id,
            label: x.id_key + " - " + x.name,
            data: x.id,
            icon: "pi pi-fw pi-book",
            level: x.level,
            parent: x.id_accounting_ledger_account_parent
          }
        });
        this.nodes = nodes.filter(x => x.level == 0);
        this.nodes.forEach(e1 => {
          console.log(nodes);
          e1.children = nodes.filter(x1 => x1.parent == e1.data)
        });

        //* Limpiamos la entidad
        this.entity = new AccountingPolicy(this.session);
        this.$toast.add({
          severity: "success",
          summary: "Eliminacion",
          detail: "Registro eliminado con exito",
          life: 3000,
        });
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    async exportCSV() {
      this.loading = true;
      try {
        await this.entity.export({
          id_company: this.session.company,
          id_branch: this.session.id_branch,
        }, "cuentas_contables.xlsx");
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    async refresh() {
      this.loading = true;
      try {
        if (this.id) {
          this.entity = new AccountingPolicy(this.session);
          this.entity.id = this.id;
          let myPolicy = await this.entity.retrieve();
          console.log(myPolicy);
          this.selectPolicy(myPolicy);
        }else {
          this.entities = await this.entity.all();
        }
        this.types = await new AccountingPolicyType(this.session).all();
        this.centres = await new AccountingCostCenter(this.session).all();
        this.accounts = await new AccountingLedgerAccount(this.session).all();
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.table-header {
  display: flex;
  justify-content: space-between;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
</style>