import {
    Abstract
} from './Abstract';

import axios from "axios";
export class AccountingPolicyConfiguration extends Abstract {
    constructor(session) {
        super('accounting/AccountingPolicyConfiguration', session);
        this.type = 'CARGO';
        this.id_accounting_policy_type = null;
        this.description = null;
        this.details = [];
    }

    async generateInvoicePolicy(id) {
        let response = await axios.post(this.controller + "/generate/FACTURA", {
            id: id.toString()
        }, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }
}