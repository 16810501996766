<template>
    <div class="card">
        <Dialog v-model:visible="customerDialog" :closable="true" :style="{ width: '75vw' }" :modal="true">
            <CustomerUI @update="updateCustomer" @save="saveCustomer" :id="entity.id_customer" :modal="true" />
        </Dialog>
        <Dialog v-model:visible="serieDialog" :closable="true" :style="{ width: '75vw' }" :modal="true">
            <SerieUI @save="saveSerie" :modal="true" />
        </Dialog>
        <Loader v-model="loading" />
        <ConfirmDeleteDialog @closed="deleteDialog = false" v-model="deleteDialog" @deleted="deleteConcept" /> 
        <Helper v-model="customerHelper" header="Buscar Cliente" :headers="customersHeaders" :rows="customers" @selected="selectCustomer" />
        <Helper v-model="consignmentNoteHelper" header="Buscar Carta Porte" :headers="consignmentNotesHeaders" :rows="consignmentNotes" @selected="selectConsignmentNote" />
        <Helper v-model="orderHelper" header="Buscar Pedido" :headers="ordersHeaders" :rows="orders" @selected="selectOrder" />
        <Helper v-model="invoiceHelper" header="Buscar Factura" :headers="invoiceHeaders" :rows="invoicesBySerie" @selected="selectInvoice" />
        <Email @error="errorEmail"  @sended="sendedEmail" v-model="emailDialog" :document="entity.id" :title="emailTitle" :key_name="'ENVIO_FACTURAS'" :to="entity.receiver_email"  />
        <Toast />
        <Panel header="Factura">
            <BasicFormToolbar :actions="entity.cancelation_status == '201' ? ['new'] : ['new','save','delete']" 
            @delete="deleteEntity" @new="openNew" @save="save">
                <template #custom-left v-if="entity.cancelation_status != '201'">
                    <Button label="Enviar Correo" icon="pi pi-envelope" class="p-button-warning mr-2" @click="sendEmail" />
                    <Button label="Timbrar XML" icon="pi pi-globe" class="p-button-warning mr-2" @click="certificateInvoice" />
                    <!-- Estos botones iran afuera como iconos -->
                    <!-- <Button label="Descargar XML" icon="pi pi-file" class="p-button-info mr-2" @click="downloadXML" /> -->
                    <!-- <Button label="Descargar PDF" icon="pi pi-file-pdf" class="p-button-danger mr-2" @click="previewPDF" /> -->
                </template>
            </BasicFormToolbar>
            <div class="grid">
                <div class="col-12">
                     <Panel header="Facturacion CFDI 4.0">
                        <TabView>
                        <TabPanel header="Informacion General">
                            <Panel header="Informacion General">
                                <div class="p-fluid formgrid grid">
                                <FormDropdown @change="seriesChanged" wrapperClass="field col-2"  v-model="entity.series" :disabled="entity.id" :valid="validate.validations.series" :options="series" :optionLabel="'serie'" :optionValue="'serie'" 
                                :add="true" @add="serieDialog = true" :label="'Serie'" />
                                <FormInputText v-model="entity.id_invoice" :label="'Folio'" :wrapperClass="'field col-2'" :search="true" @search="invoiceHelper.visible = true" />
                                <FormInputSwitch label="CFDI Global" :wrapperClass="'field col-1'" v-model="is_global" />
                                <div class="col-2">
                                    <FileIcon width="3.5rem" extension="xml" @click="downloadXML" />
                                    <FileIcon width="3.5rem" extension="pdf" @click="downloadPDF"/>
                                </div>
                                <FormCalendar v-model="entity.date" :valid="validate.validations.date" :disabled="entity.id" :label="'Fecha'" :wrapperClass="'field col-2 col-offset-3'" />
                                <FormDropdownComplex :labelInOption="'c_FormaPago - Descripcion'" :labelInValue="'c_FormaPago - Descripcion'" :wrapperClass="'field col-4'" label="Forma de Pago" v-model="entity.payment_form" :options="c_FormaPago" optionLabel="c_FormaPago" optionValue="c_FormaPago" />
                                <FormDropdownComplex :labelInOption="'c_MetodoPago - Descripcion'" :labelInValue="'c_MetodoPago'" :wrapperClass="'field col-2'" label="Metodo de Pago" v-model="entity.payment_method" :options="c_MetodoPago" optionLabel="c_MetodoPago" optionValue="c_MetodoPago" />
                                <FormInputText v-model="entity.expedition_place" wrapperClass="field col-2" label="Lugar de Expedicion" :valid="validate.validations.expedition_place"/>
                                <FormDropdownComplex :labelInOption="'c_TipoDeComprobante - Descripcion'" :labelInValue="'Descripcion'" :wrapperClass="'field col-2'" label="Tipo" v-model="entity.voucher_type" :options="c_TipoDeComprobanteByFactura" optionLabel="c_TipoDeComprobante" optionValue="c_TipoDeComprobante" />
                                <FormDropdownComplex :labelInOption="'c_Exportacion - Descripcion'" :labelInValue="'Descripcion'" :wrapperClass="'field col-2'" label="Exportacion" v-model="entity.export" :options="c_Exportacion" optionLabel="c_Exportacion" optionValue="c_Exportacion" />
                                <FormDropdownComplex :labelInOption="'c_Moneda - Descripcion'" :labelInValue="'c_Moneda'" :wrapperClass="'field col-2'" label="Moneda" v-model="entity.currency" :options="c_Moneda" optionLabel="c_Moneda" optionValue="c_Moneda" />
                                <FormInputNumber label="Tasa de Cambio" :disabled="entity.currency == 'MXN'" wrapperClass="field col-2" :valid="validate.validations.exchange_rate" v-model="entity.exchange_rate" mode="currency" />
                            </div>
                            </Panel>
                        </TabPanel>
                        <TabPanel header="Informacion Adicional">
                                <div class="p-fluid formgrid grid">
                                    <FormInputText v-model="entity.id_consignment_note" :label="'Carta Porte'" :wrapperClass="'field col-2'" :search="entity.cancelation_status != '201'" @search="consignmentNoteHelper.visible = true" :tooltip="'Dar de alta en esta ruta: Aplicaciones/CFDI 4.0/Carta Porte'"/>
                                    <FormInputText v-model="entity.id_order" :label="'Pedido'" :wrapperClass="'field col-2'" :search="entity.cancelation_status != '201'" @search="orderHelper.visible = true" :tooltip="'Dar de alta en esta ruta: Catalogos/Comercial/Pedido'"/>
                                </div>
                            </TabPanel>
                        <TabPanel header="Informacion Fiscal">
                            <div class="p-fluid formgrid grid">
                                <div class="col-6">
                                    <Panel header="CFDI">
                                        <div class="p-fluid formgrid grid">
                                        <FormInputText wrapperClass="field col-12" :readonly="true" :label="'UUID'" v-model="entity.uuid" />
                                        <div class="field col-12">
                                            <label>Estatus</label>
                                            <InputText :style="{
                                                color: getEstatus(entity) == 'CANCELADA' ? 'red' : getEstatus(entity) == 'TIMBRADA' ? 'green' : null,
                                                borderColor: getEstatus(entity) == 'CANCELADA' ? 'red' : getEstatus(entity) == 'TIMBRADA' ? 'green' : null
                                            }" :value="getEstatus(entity)" :readonly="true" />
                                        </div>
                                    </div>
                                    </Panel>
                                </div>
                                <div class="col-6">
                                    <ComprobanteCancel @acuse-pdf="downloadAcusePDF" @acuse="downloadAcuse" v-model="entity" :customer="entity.receiver_rfc" :uuid="entity.uuid" v-if="entity.is_certificated"/>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Totales">
                            <Panel header="Totales">
                                <div class="p-fluid formgrid grid">
                                <FormInputNumber label="Retenidos" wrapperClass="field col-2" v-model="entity.taxes_total_detained" mode="currency"  :readonly="true"/>
                                <FormInputNumber label="Trasladados" wrapperClass="field col-2" v-model="entity.taxes_total_transferred" mode="currency" :readonly="true"/>
                                <FormInputNumber label="SubTotal" wrapperClass="field col-2" v-model="entity.subtotal" mode="currency" :readonly="true"/>
                                <FormInputNumber label="Descuento" wrapperClass="field col-2" v-model="entity.discount" mode="currency" :readonly="true"/>
                                <FormInputNumber label="Impuestos" wrapperClass="field col-2" v-model="entity.taxes_total" mode="currency" :readonly="true"/>
                                <FormInputNumber label="Total" wrapperClass="field col-2" v-model="entity.total" mode="currency" :readonly="true"/>
                            </div>
                            </Panel>
                        </TabPanel>
                     </TabView>
                     </Panel>
                </div>
                <br />
                <div class="col-6" style="min-height: 100%">
                    <ComprobanteIssuer :c_RegimenFiscal="c_RegimenFiscal" :entity="entity" :certificate="branch.certificate" />
                </div>
                <div class="col-6">
                    <Fieldset :toggleable="true" legend="Informacion Receptor">
                        <div class="p-fluid formgrid grid">
                            <div class="field col-12">
                                <label for="">RFC</label>
                                <div class="p-inputgroup">
                                    <InputText @keydown.enter.exact="searchByRFC" v-model="entity.receiver_rfc" :class="{ 'p-invalid': validate.validations.receiver_rfc === false }" />
                                    <Button v-if="entity.cancelation_status != '201'" v-tooltip.top="{value:'Seleccionar cliente', class: 'custom-tool'}" @click="customerHelper.visible = true" icon="pi pi-search" class="p-button-primary" />
                                    <Button v-if="entity.cancelation_status != '201'" v-tooltip.top="{value:'Crear cliente', class: 'custom-tool'}" @click="customerDialog = true" icon="pi pi-plus" class="p-button-warning" />
                                    <Button v-if="entity.cancelation_status != '201'" v-tooltip.top="{value:'Limpiar datos', class: 'custom-tool'}" @click="newCustomer" icon="pi pi-refresh" class="p-button-info" />
                                </div>
                                <small class="p-invalid" v-if="validate.validations.receiver_rfc === false"> El campo de RFC solo puede tener 12 o 13 caracteres </small>
                            </div>
                            <FormInputText v-model="entity.receiver_name" wrapperClass="field col-12" label="Nombre / Razon Social" :valid="validate.validations.receiver_name"/>
                            <FormDropdownComplex :filterFields="['c_RegimenFiscal', 'Descripcion']" :valid="validate.validations.receiver_tax_regime" :labelInOption="'c_RegimenFiscal - Descripcion'" :labelInValue="'c_RegimenFiscal - Descripcion'" :wrapperClass="'field col-12'" label="Regimen Fiscal" v-model="entity.receiver_tax_regime" :options="regimenFiscalReceptor" optionLabel="c_RegimenFiscal" optionValue="c_RegimenFiscal" />
                            <FormInputText v-model="entity.receiver_tax_residence" wrapperClass="field col-12" label="Direccion"/>
                            <FormInputText v-model="entity.receiver_postal_code" :valid="validate.validations.receiver_postal_code" wrapperClass="field col-12" label="Codigo Postal"/>
                            <FormDropdownComplex :filterFields="['c_UsoCFDI', 'Descripcion']" :valid="validate.validations.receiver_credit_note_use" :labelInOption="'c_UsoCFDI - Descripcion'" :labelInValue="'c_UsoCFDI - Descripcion'" :wrapperClass="'field col-12'" label="Uso CFDI" v-model="entity.receiver_invoice_use" :options="c_UsoCFDI" optionLabel="c_UsoCFDI" optionValue="c_UsoCFDI" />
                            <FormInputText v-model="entity.receiver_email" wrapperClass="field col-12" label="Correo Electronico" :valid="validate.validations.receiver_email" validation="Favor de llenar el campo, ademas usar formato de correo"/>
                        </div>
                    </Fieldset>
                </div>
                <FadeInOut entry="left" exit="right" :duration="350" mode="out-in">
                    <div v-if="is_global" class="col-12">
                        <Fieldset :toggleable="true" :collapsed="20" legend="Información Global">
                            <div class="p-fluid formgrid grid">
                                <FormDropdownComplex :valid="validate.validations.global_information_periodicity" :labelInOption="'c_Periodicidad - Descripcion'" :labelInValue="'c_Periodicidad - Descripcion'" :wrapperClass="'field col-4'" label="Periodicidad" v-model="entity.global_information_periodicity" :options="c_Periodicidad" optionLabel="c_Periodicidad" optionValue="c_Periodicidad" />
                                <FormDropdownComplex :valid="validate.validations.global_information_months" :labelInOption="'c_Meses - Descripcion'" :labelInValue="'c_Meses - Descripcion'" :wrapperClass="'field col-4'" label="Mes" v-model="entity.global_information_months" :options="c_Meses" optionLabel="c_Meses" optionValue="c_Meses" />
                                <FormDropdownComplex :valid="validate.validations.global_information_anio" :labelInOption="'c_Anios - Descripcion'" :labelInValue="'c_Anios - Descripcion'" :wrapperClass="'field col-4'" label="Año" v-model="entity.global_information_anio" :options="c_Anios" optionLabel="c_Anios" optionValue="c_Anios" />
                            </div>
                        </Fieldset>
                    </div>
                </FadeInOut>
                <div class="col-12">
                    <ComprobanteConcept @delete="deleteConcept" @save="saveConcepto" @update="updateConcepto" 
                    :c_ClaveUnidad="c_ClaveUnidad" :c_ObjetoImp="c_ObjetoImp" 
                    :concepts="entity.invoice_concepts" version="invoice" :canceled="entity.cancelation_status == '201'" />
                </div>
            </div>
        </Panel>
    </div>
</template>

<script>
import ComprobanteCancel from "../Aplicaciones/Components/ComprobanteCancel.vue";
import ComprobanteIssuer from "../Aplicaciones/Components/ComprobanteIssuer.vue";
import ComprobanteConcept from "../Aplicaciones/Components/ComprobanteConcept.vue";
import CustomerUI from "../../Comercial/Catalogos/Customer.vue";
import { Invoice } from '../../../models/cfdi40/Invoice';
import { InvoiceConcept } from '../../../models/cfdi40/InvoiceConcept';
import { Branch } from '../../../models/general/Branch';
import { Rule, fillObject, ErrorToast, satCatalogo, HeaderGrid, validate } from '../../../utilities/General';
import Loader from '../../../components/general/Loader.vue';
import Helper from '../../../components/general/HelperDialog.vue';
import Email from '../../../components/general/EmailForm.vue';
import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue';

import FormInputText from '../../../components/general/FormInputText.vue';
import FormInputNumber from '../../../components/general/FormInputNumber.vue';
import FormInputSwitch from '../../../components/general/FormInputSwitch.vue';
import FormCalendar from '../../../components/general/FormCalendar.vue';
import FormDropdown from '../../../components/general/FormDropdown.vue';
import FormDropdownComplex from '../../../components/general/FormDropdownComplex.vue';

import FileIcon from "../../../components/general/FileIcon.vue";
import SerieUI from "../../Comercial/Catalogos/Serie.vue";

import Session from '../../../mixins/sessionMixin';
import { Customer } from '../../../models/comercial/Customer';
import { InvoiceSerie } from '../../../models/comercial/InvoiceSerie';
import ConfirmDeleteDialog from '../../../components/general/DeleteDialog.vue';
import { ConsignmentNote } from '../../../models/cfdi40/ConsingmentNote';
import { Order } from '../../../models/comercial/Order';
import { BranchCertificate } from '../../../models/general/BranchCertificate';

export default {
    mixins: [Session],
    props: {
        id: null,
        view: null,
    },
    data() {
        return {
            is_global: false,
            deleteDialog: false,
            entity: null,
            entities: [],
            customers: [],
            consignmentNotes: [],
            orders: [],
            invoices: [],
            series: [],
            c_Periodicidad: [],
            c_Meses: [],
            emailDialog: {
                visible: false,
                html: '',
            },
            customersHeaders: [
                new HeaderGrid('Nombre', 'name'),
                new HeaderGrid('Apellidos', 'last_name'),
                new HeaderGrid('RFC', 'rfc'),
                new HeaderGrid('Direccion', 'address'),
                new HeaderGrid('CP', 'postal_code'),
                new HeaderGrid('Pais', 'country'),
                new HeaderGrid('Estado', 'state'),
                new HeaderGrid('Municipio', 'municipality'),
                new HeaderGrid('Colonia', 'suburb'),
                new HeaderGrid('Regimen Fiscal', 'tax_regime'),
            ],
            consignmentNotesHeaders: [
                new HeaderGrid('Folio', 'id'),
                new HeaderGrid('SAT Codigo Transporte', 'transport_sat_code'),
                new HeaderGrid('No. Mercancias', 'goods_number'),
                new HeaderGrid('Folio Control Vehicular', 'id_consignment_note_vehicle_control'),
                new HeaderGrid('Via', 'entry_exit_good'),
                new HeaderGrid('SAT Codigo Vehicular', 'vehicle_config_sat_code'),    
            ],
            ordersHeaders: [
                new HeaderGrid('Folio', 'id'),
                new HeaderGrid('Fecha', 'order_date'),
                new HeaderGrid('Cliente', 'name'),
                new HeaderGrid('Moneda', 'currency'),
                new HeaderGrid('SubTotal', 'subtotal', { type: 'currency'}),
                new HeaderGrid('Descuento', 'discount1', { type: 'currency'}),   
                new HeaderGrid('Total', 'total', { type: 'currency'}), 
            ],
            invoiceHeaders: [
                new HeaderGrid('Serie', 'series'), 
                new HeaderGrid('Folio', 'id_invoice'), 
                new HeaderGrid('Fecha', 'date', { type: 'date-time'} ),
                new HeaderGrid('Receptor', 'receiver_name'),
                new HeaderGrid('RFC Receptor', 'receiver_rfc'),
                new HeaderGrid('Total', 'total', { type: 'currency'} ),
                new HeaderGrid('Estatus', 'status_name')
            ],
            branch: new Branch(this.session),
            concept: new InvoiceConcept(this.session),
            c_MetodoPago: [],
            c_FormaPago: [],
            c_TipoDeComprobante: [],
            c_Exportacion: [],
            c_Moneda: [],
            c_RegimenFiscal: [],
            c_UsoCFDI: [],
            c_ClaveUnidad: [],
            c_TipoRelacion: [],
            c_TipoRelacionCancelacion: [],
            c_ObjetoImp: [
                { c_ObjetoImp: '01', Descripcion: 'No objeto de impuesto.' },
                { c_ObjetoImp: '02', Descripcion: 'Sí objeto de impuesto.' },
                { c_ObjetoImp: '03', Descripcion: 'Sí objeto del impuesto y no obligado al desglose.' },
            ],
            c_Impuestos: [
                { c_Impuestos: '01', Descripcion: 'ISR' },
                { c_Impuestos: '02', Descripcion: 'IVA' },
                { c_Impuestos: '03', Descripcion: 'IEPS' },
            ],
            c_Anios: [
                { c_Anios: '2021', Descripcion: '2021'},
                { c_Anios: '2022', Descripcion: '2022'},
                { c_Anios: '2023', Descripcion: '2023'},
            ],
            rules: [
                new Rule({ name: 'series' }),
                new Rule({ name: 'payment_form' }),
                new Rule({ name: 'currency' }),
                new Rule({ name: 'exchange_rate' }),
                new Rule({ name: 'voucher_type' }),
                new Rule({ name: 'export' }),
                new Rule({ name: 'payment_method' }),
                new Rule({ name: 'expedition_place' }),
                new Rule({ name: 'issuing_rfc', type: 'rfc' }),
                new Rule({ name: 'issuing_name' }),
                new Rule({ name: 'issuing_tax_regime' }),
                new Rule({ name: 'receiver_rfc', type: 'rfc' }),
                new Rule({ name: 'receiver_name' }),
                new Rule({ name: 'receiver_postal_code' }),
                new Rule({ name: 'receiver_tax_regime' }),
                new Rule({ name: 'receiver_email', type: 'email' }),
                new Rule({ name: 'receiver_invoice_use' }),
            ],
            validate: {
                valid: false,
                validations: {
                    series: null,
                    payment_form: null,
                    currency: null,
                    exchange_rate: null,
                    voucher_type: null,
                    export: null,
                    payment_method: null,
                    expedition_place: null,
                    issuing_rfc: null,
                    issuing_name: null,
                    issuing_tax_regime: null,
                    receiver_rfc: null,
                    receiver_name: null,
                    receiver_postal_code: null,
                    receiver_tax_regime: null,
                    receiver_invoice_use: null,
                    receiver_email: null,
                },
            },
            loading: false,
            customerHelper: {
                visible: false,
            },
            customerDialog: false,
            serieDialog: false,
            consignmentNoteHelper: {
                visible: false,
            },
            orderHelper: {
                visible: false,
            },
            invoiceHelper: {
                visible: false,
            },
            concepts: []
        };
    },
    components:  { ComprobanteCancel, FormDropdownComplex, ComprobanteIssuer ,ComprobanteConcept, FormDropdown, FormCalendar, SerieUI, CustomerUI, FileIcon, FormInputSwitch,ConfirmDeleteDialog, BasicFormToolbar, FormInputNumber, Loader, Helper, FormInputText, Email },
    created() {
        this.entity = new Invoice(this.session);
    },
    async mounted() {
        this.loading = true;
        await this.refresh();
        //Cargamos la sucursal
        this.branch.id = this.session.branch;
        let myBranch = await this.branch.retrieve();
        this.branch = fillObject(this.branch, myBranch);
        if (!myBranch.certificate) {
            this.$toast.add(new ErrorToast('No se encontro ningun CSD para esta sucursal, no podrá realizar ninguna factura, favor de verificar esta informacion', { severity: "warn", summary: "Advertencia"}));
            this.branch.certificate = new BranchCertificate(this.session);
        }else{
            this.branch.certificate = fillObject(this.branch.certificate,myBranch.certificate);
            this.branch.certificate.initial_date =  new Date(this.branch.certificate.initial_date);
            this.branch.certificate.final_date =  new Date(this.branch.certificate.final_date);
        }
        //Agregamos los valores estaticos
        this.entity.issuing_rfc = this.branch.tax_number;
        this.entity.issuing_name = this.branch.name.toUpperCase();
        this.entity.issuing_tax_regime = this.branch.tax_regime;
        //* El Codigo Postal tambien se agregara
        this.entity.expedition_place = this.branch.zip_code;

        //Aqui cargamos los CATALOGOS ESTATICOS
        this.c_MetodoPago = await satCatalogo(3);
        this.c_FormaPago = await satCatalogo(6);
        this.c_TipoDeComprobante = await satCatalogo(13);
        this.c_Exportacion = await satCatalogo(5);
        this.c_Moneda = await satCatalogo(9);
        this.c_RegimenFiscal = await satCatalogo(2);
        this.c_UsoCFDI = await satCatalogo(15);
        this.c_TipoRelacion = await satCatalogo(14);
        this.c_TipoRelacionCancelacion = await satCatalogo(17);
        this.c_Periodicidad = await satCatalogo(18),
        this.c_Meses = await satCatalogo(19),
        (this.c_ClaveUnidad = await satCatalogo(16)), (this.loading = false);
    },
    computed: {
        emailTitle() {
            return this.entity.series + "-" + this.entity.id_invoice;
        },
        c_TipoDeComprobanteByFactura() {
            return this.c_TipoDeComprobante.filter(x => x.c_TipoDeComprobante != "E")
        },
        invoicesBySerie() {
            return this.entity.series 
                ? this.invoices.filter(x => x.series == this.entity.series)
                                .sort((a, b) => { return a.id_invoice - b.id_invoice; })
                                .sort((a,b) => (a.series > b.series) ? 1 : ((b.series > a.series) ? -1 : 0))
                : this.invoices.filter(x => x.voucher_type == "I")
                                .sort((a, b) => { return a.id_invoice - b.id_invoice; })
                                .sort((a,b) => (a.series > b.series) ? 1 : ((b.series > a.series) ? -1 : 0))
        },
          regimenFiscalReceptor() {
            return this.entity.receiver_rfc ? 
                    this.entity.receiver_rfc.length > 12 
                    ? this.c_RegimenFiscal.filter(x => x.Fisica == 'Sí')
                    : this.c_RegimenFiscal.filter(x => x.Moral == 'Sí')
                : this.c_RegimenFiscal.filter(x => x.Fisica == 'Sí');
        },
    },
    watch: {
        'is_global'(newValue){
            this.entity.receiver_rfc = newValue ? "XAXX010101000" : null;
        },
        'entity.receiver_rfc'(newValue){
            if(newValue){
                 this.receiver_tax_regime = null;
                
                if(newValue == "XAXX010101000")
                {
                    this.is_global = true;
                    this.entity.receiver_name = "PUBLICO EN GENERAL";
                    this.entity.receiver_postal_code = this.entity.expedition_place;
                    this.entity.receiver_tax_regime = "616";
                    this.c_UsoCFDI = this.c_UsoCFDI.filter(x => x.c_UsoCFDI == 'S01' || x.c_UsoCFDI == 'CP01');
                }
                else
                    this.is_global = false;

                if(newValue== "XEXX010101000")
                {
                    this.entity.receiver_postal_code = this.entity.expedition_place;
                    this.is_global = false;
                    this.entity.receiver_tax_regime = "616";
                    this.c_UsoCFDI = this.c_UsoCFDI.filter(x => x.c_UsoCFDI == 'CP01' || x.c_UsoCFDI == 'S01');
                }
            }
        },
        'entity.currency'(newValue, oldValue) {
            this.entity.exchange_rate = newValue == 'MXN' ? 1.0 : oldValue;
        },
        'concept.tax_object'(newValue) {
            if (newValue == '01') {
                this.concept.iva = 0;
                this.concept.ieps = 0;  
                this.concept.iva_detained = 0;
                this.concept.ieps_detained = 0;
            } else {
                this.concept.iva = 16;
                this.concept.ieps = 0;
            }
        },
        'concept.unit_key'(newValue, oldValue) {
            this.concept.unit_name = newValue ? this.c_ClaveUnidad.find((x) => x.c_ClaveUnidad == newValue).Nombre.toUpperCase() : oldValue;
        },
    },
    methods: {
        errorEmail(error) {
            this.$toast.add(new ErrorToast(error));
        },
        sendedEmail() {
            this.emailDialog.visible = false;
            this.$toast.add({
                severity: 'success',
                summary: 'Factura',
                detail: 'Correo enviado con exito',
                life: 3000,
            });
        },
        newCustomer() {
            this.entity.id_customer = null;
            this.entity.receiver_rfc = null;
            this.entity.receiver_name = null;
            this.entity.receiver_tax_regime = null;
            this.entity.receiver_postal_code = null;
            this.entity.receiver_tax_residence = null;
            this.entity.receiver_email = null;
            this.entity.receiver_invoice_use = null;
        },
        confirmDelete() {
            this.deleteDialog = true;
        },
        deleteConcept(concept) {
            let id_number = concept.id_number;
            this.entity.invoice_concepts = this.entity.invoice_concepts.filter(x => x.id_number != concept.id_number);
            this.entity.invoice_concepts.forEach(x => {
                if (x.id_number > id_number) {
                    x.id_number = x.id_number - 1;
                }
            });
        },
        seriesChanged() {
            this.entity.id_invoice = this.series.find((x) => x.serie == this.entity.series).next_id;
        },
        sendEmail() {
            try {
                if (!this.entity.id)
                    throw "No es posible enviar correo, factura no generada";
                if (!this.entity.is_certificated) {
                    throw "No es posible enviar correo, factura no certificada";
                }
                this.emailDialog.visible = true;
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            }
        },
        getEstatus(invoice) {
            if (!invoice.id) return 'N/A';
            else if (invoice.cancelation_status == 201) return "CANCELADA";
            else if (invoice.is_certificated) return 'TIMBRADA';
            else return 'REGISTRADA';
        },
        selectConsignmentNote(consignment) {
            this.entity.id_consignment_note = consignment.id;
        },
        async selectOrder(order) {
            try {
                this.loading = true;
                this.entity.id_order = order.id;
                var invoice = await this.entity.order();
                this.entity = fillObject(this.entity, invoice);


            /* Llenamos el detalle*/
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        selectCustomer(customer) {
            this.entity.id_customer = customer.id; 
            this.entity.receiver_rfc = customer.rfc;
            this.entity.receiver_name = customer.name;
            this.entity.receiver_tax_regime = customer.tax_regime;
            this.entity.receiver_postal_code = customer.postal_code;
            this.entity.receiver_tax_residence = customer.address + ' ' + customer.suburb + ', ' + customer.municipality + ', ' + customer.state + ', ' + customer.country;
            this.entity.receiver_email = customer.email;
            this.entity.receiver_invoice_use = customer.use_cfdi;
        },
        async selectInvoice(invoice) {
            try {
                this.loading = true;
                this.entity = fillObject(this.entity, invoice);
                if (this.entity.date) this.entity.date = new Date(this.entity.date);

                //* Llenamos los conceptos
                this.entity.invoice_concepts = await this.entity.concepts();
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async downloadXML() {
            try {
                await this.entity.downloadXML();
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } 
        },
        async downloadAcusePDF() {
            try {
                await this.entity.downloadAcusePDF();
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } 
        },
        async downloadAcuse() {
            try {
                await this.entity.downloadAcuseXML();
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } 
        },
        async downloadPDF() {
             try {
                this.loading = true;
                await this.entity.downloadPDF();
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally { 
                this.loading = false;
            }
        },
        searchByRFC() {
            if (this.entity.receiver_rfc) {
                try {
                    let customer = this.customers.find(x => x.rfc == this.entity.receiver_rfc);
                    if (!customer) {
                        throw "No se encontro un cliente con el rfc " + this.entity.receiver_rfc
                    }
                    this.selectCustomer(customer);
                } catch (error) {
                    this.$toast.add(new ErrorToast(error));
                }
            }
        },
         searchByCartaPorte() {
            if (this.entity.id_consignment_note) {
                try {
                    let consignmentNote = this.consingmentNotes;
                    this.selectConsignmentNote(consignmentNote);
                } catch (error) {
                    this.$toast.add(new ErrorToast(error));
                }
            }
        },
        saveConcepto(concept) {
            console.log("Conceptos: " +JSON.stringify(concept))
            this.entity.invoice_concepts.push(concept);
        },
        updateConcepto(concept) {
            this.entity.invoice_concepts[this.entity.invoice_concepts.findIndex(x => x.id_number == concept.id_number)] = concept;
        },
        async openNew() {
            try {
                this.loading = true;
                this.entity = new Invoice(this.session);
                this.concept = new InvoiceConcept(this.session);
                this.branch.id = this.session.branch;
                this.branch = fillObject(this.branch, await this.branch.retrieve());
                //Agregamos los valores estaticos
                this.entity.issuing_rfc = this.branch.tax_number;
                this.entity.issuing_name = this.branch.name.toUpperCase();
                this.entity.issuing_tax_regime = this.branch.tax_regime;
                this.entity.expedition_place = this.branch.zip_code;
                this.newDialog = true;
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        hideDialog() {
            this.newDialog = false;
        },
        previewPDF() {
            if (this.entity.id) {
                this.$router.push('/cfdi40/herramientas/factura/pdf/' + this.entity.id);
            } else {
                this.$toast.add(new ErrorToast('No se puede ver el PDF, no existe la factura'));
            }
        },
        async save() {
            try {
                //* Validacion de form
                this.loading = true;
                this.validate = validate(this.entity, this.rules);
                if (!this.validate.valid) 
                {
                    console.log(this.validate); 
                    throw 'Favor de validar los campos';
                }
                if (!this.entity.id_customer) throw 'Favor de seleccionar un cliente';
                if (this.entity.is_certificated) throw 'No se puede modificar una factura ya TIMBRADA'
                
                if (this.entity.id) {
                    let invoice = await this.entity.regenerate();
                    this.entity.taxes_total_detained = invoice.taxes_total_detained;
                    this.entity.taxes_total_transferred = invoice.taxes_total_transferred; 
                    this.entity.subtotal = invoice.subtotal;
                    this.entity.discount = invoice.discount;
                    this.entity.taxes_total = invoice.taxes_total_detained + invoice.taxes_total_transferred;
                    this.entity.total = invoice.total;
                    this.entity.xmLfilename = invoice.xmLfilename;
                    this.entity.invoice_concepts = invoice.invoice_concepts;
                    this.entities[this.entities.findIndex(x => x.id == this.entity.id)] = {...this.entity};
                }else {
                    let invoice = await this.entity.generate();
                    this.entity.id_number = invoice.id_number;
                    this.entity.id = invoice.id;
                    this.entity.taxes_total_detained = invoice.taxes_total_detained;
                    this.entity.taxes_total_transferred = invoice.taxes_total_transferred; 
                    this.entity.subtotal = invoice.subtotal;
                    this.entity.discount = invoice.discount;
                    this.entity.taxes_total = invoice.taxes_total_detained + invoice.taxes_total_transferred;
                    this.entity.total = invoice.total;
                    this.entity.xmLfilename = invoice.xmLfilename;
                    this.entity.invoice_concepts = invoice.invoice_concepts;
                    this.invoices.push(invoice);
                }
                this.$toast.add({
                    severity: 'success',
                    summary: 'Factura',
                    detail: 'Informacion actualizada con exito',
                    life: 3000,
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async certificateInvoice() {
            try {
                if (this.entity.is_certificated) throw 'Esta factura ya esta TIMBRADA'

                this.loading = true;
                let response = await this.entity.certificate();
                this.$toast.add({
                    severity: 'success',
                    summary: 'Timbrado',
                    detail: "Se ha certificado el XML con exito",
                    life: 3000,
                });
                //* Datos de certificados
                this.entity.uuid = response.uuid;
                this.entity.certification_date = response.certification_date;
                this.entity.original_chain = response.original_chain;
                this.entity.sat_certificate = response.sat_certificate;
                this.entity.sat_stamp = response.sat_stamp;
                this.entity.is_certificated = true;
            } catch (error) {
                this.$toast.add(new ErrorToast(error, {life: 7500}));
            } finally {
                this.loading = false;
            }
        },
        edit(entity) {
            this.entity = fillObject(this.entity, entity);
            this.newDialog = true;
        },
        async deleteEntity() {
            try {
                this.loading = true;
                //* Eliminamos de la base
                await this.entity.delete();
                //* Eliminamos de la vista
                this.entities = this.entities.filter((val) => val.id !== this.entity.id);
                this.deleteDialog = false;
                //* Limpiamos la entidad
                this.entity = new Invoice(this.session);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Eliminacion',
                    detail: 'Registro eliminado con exito',
                    life: 3000,
                });
                this.$emit('deleted');
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        updateCustomer(customer) {
            this.customerDialog = false;
            this.selectCustomer(customer);
            this.customers[this.customers.findIndex(x => x.id == customer.id)] = customer;
        },
        saveCustomer(customer) {
            this.customerDialog = false;
            this.selectCustomer(customer);
            this.customers.push(customer);
        },
        saveSerie(serie) {
            this.serieDialog = false;
            this.series.push(serie);
            this.entity.voucher_type = serie.voucher_type;
            this.entity.series = serie.serie;
            this.entity.id_invoice = serie.next_id;
        },
        async refresh() {
            try {
                //Si tiene un id lo cargamos
                if (this.id) {
                    this.entity.id = this.id;
                    let retrieved = await this.entity.retrieve();
                    this.selectInvoice(retrieved);
                }
                this.customers = await new Customer(this.session).all();
                this.invoices = await new Invoice(this.session).all();
                this.series = await new InvoiceSerie(this.session).all();
                this.series = this.series.filter(x => x.voucher_type != 'E');
                this.consignmentNotes = await new ConsignmentNote(this.session).all();
                this.orders = await new Order(this.session).all()
                
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            }
        },
    },
};
</script>

<style scoped lang="scss">

.table-header {
    display: flex;
    justify-content: space-between;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-button-inline {
    margin-top: 1.7rem;
}

.custom-tool .p-tooltip-text {
    background-color: var(--gray-800);
    color: rgb(255, 255, 255);
}
.custom-tool .p-tooltip-top .p-tooltip-arrow {
    border-right-color: var(--gray-800);
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-toolbar) {
        flex-wrap: wrap;

        .p-button {
            margin-bottom: 0.25rem;
        }
    }
}
</style>
