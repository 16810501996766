import {
    Abstract
} from './Abstract';

import axios from 'axios';

export class AccountingPolicy extends Abstract {
    constructor(session) {
        super('accounting/AccountingPolicy', session);
        this.id_number= null;
        this.id_accounting_policy_type = null;
        this.date = new Date();
        this.currency = "MXN";
        this.exchange_rate = 1.00;
        this.description = null;
        this.details = [];
        this.status = 1;
    }

    async getDetails() {
        if (this.id == 0 || this.id == "" || this.id == null)
            throw "ERROR. Seleccione un registro"
        let response = await axios.get(this.controller + '/details/' + this.id, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }

    async afectar() {
        if (this.id == 0 || this.id == "" || this.id == null)
            throw "ERROR. Seleccione un registro"
        let response = await axios.put(this.controller + '/afectar/' + this.id, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }

    async desafectar() {
        if (this.id == 0 || this.id == "" || this.id == null)
            throw "ERROR. Seleccione un registro"
        let response = await axios.put(this.controller + '/desafectar/' + this.id, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }

    async deleteDetail(id) {
        if (id == 0 || id == "" || id == null)
            throw "ERROR. Seleccione un registro"
        let response = await axios.delete(this.controller + '/detail/' + id, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }

    async cancelar() {
        if (this.id == 0 || this.id == "" || this.id == null)
            throw "ERROR. Seleccione un registro"
        let response = await axios.put(this.controller + '/cancelar/' + this.id, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }

    async saveDetails(details) {
        let response = await axios.post(this.controller + '/details', details, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }

}