import {
    Abstract
} from './Abstract';

export class InvoiceConcept extends Abstract {
    constructor(session) {
        super('invoice/InvoiceManager', session);
        this.id_invoice = null;
        this.concept_key = null;
        this.id_number = null;
        this.quantity = 0;
        this.unit_key = null;
        this.unit_name = null;
        this.description = null;
        this.unit_value = 0;
        this.amount = 0;
        this.discount = 0;
        this.tax_object = "02";
        this.third_party_account_rfc = null;
        this.third_party_account_name = null;
        this.third_party_tax_regime = null;
        this.third_party_tax_residence = null;
        this.iva = 16;
        this.ieps = 0;
        this.iva_detained = 0;
        this.ieps_detained = 0;
        this.id_concept = null;
    }
}
