import {
    Abstract
} from './Abstract';

export class AccountingCostCenter extends Abstract {
    constructor(session) {
        super('accounting/AccountingCostCenter', session);
        this.name = null;
        this.key_name = null;
        this.type = null;
    }
}