<template>
  <Panel header="Cancelacion">
        <HelperDialog v-model="invoiceHelper" 
            header="Buscar Factura" 
            :headers="invoiceHeaders" 
            :rows="invoices" 
            @selected="selectInovice" 
        />
        <div class="p-fluid formgrid grid">
            <FormDropdownComplex :disabled="localValue.acuse" :labelInOption="'c_TipoRelacionCancelacion - Descripcion'" 
            :labelInValue="'c_TipoRelacionCancelacion - Descripcion'"
            :filterFields="['c_TipoRelacionCancelacion', 'Descripcion']"
            :wrapperClass="'field col-12'" label="Motivo" v-model="localValue.cancelation_reason" 
            :options="c_TipoRelacionCancelacion" 
            optionLabel="c_TipoRelacionCancelacion" optionValue="c_TipoRelacionCancelacion" />
            <FormInputText v-model="localValue.uuid_sustitution" v-if="localValue.cancelation_reason == '01'" :wrapperClass="'field col-12'" label="UUID Sustitucion" :readonly="true" :clear="true"  @clear="localValue.uuid_sustitution = null" :search="true" @search="showInvoices()" />
            <div v-if="!localValue.acuse" class="field col-12">
                <Button :loading="loading" :disabled="(localValue.cancelation_reason == '01' && localValue.uuid_sustitution == null) || (localValue.cancelation_reason == null)" 
                class="p-button-danger" icon="pi pi-times-circle" @click="cancel" label="Cancelar" />
            </div>
            <div v-else class="field col-12">
                <Button :loading="loading" class="p-button-info" icon="pi pi-file" @click="showAcuseXML" label="Ver Acuse XML" />
                <Button style="margin-top:1rem;" :loading="loading" class="p-button-info" icon="pi pi-file" @click="showAcusePDF" label="Ver Acuse PDF" />
            </div>
        </div>
    </Panel>
</template>

<script>
import FormInputText from "../../../../components/general/FormInputText.vue";
import FormDropdownComplex from "../../../../components/general/FormDropdownComplex.vue";
import { ErrorToast, HeaderGrid, fillObject, satCatalogo } from '../../../../utilities/General';
import { Invoice } from '../../../../models/cfdi40/Invoice';
import HelperDialog from "../../../../components/general/HelperDialog.vue";
import Session from '../../../../mixins/sessionMixin';

export default {
    mixins: [Session],
    components: { FormInputText, FormDropdownComplex, HelperDialog},
    props: {
        modelValue: {
            type: Object,
            required: () => new Invoice(),
        },
        customer: {
            type: Number,
            default: 0,
        },
        uuid: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            localValue: this.modelValue,
            c_TipoRelacionCancelacion: [],
            loading: false,
            invoices: [],
            invoiceHelper: {
                visible: false,
            },
            invoiceHeaders: [
                new HeaderGrid('Serie', 'series'), 
                new HeaderGrid('Folio', 'id_invoice'), 
                new HeaderGrid('Fecha', 'basic_date', { type: 'date'} ),
                new HeaderGrid('Receptor', 'receiver_name'),
                new HeaderGrid('RFC Receptor', 'receiver_rfc'),
                new HeaderGrid('Total', 'total', { type: 'currency'} ),
                new HeaderGrid('UUID', 'uuid')
            ],
        }
    },
    watch: {
        ["localValue.cancelation_reason"](newValue) {
            if (newValue != "01") {
                this.localValue.uuid_sustitution = null;
            }
        },
        localValue(newValue) {
            this.$emit('update:modelValue', newValue);
        },
        modelValue(newValue) {
            this.localValue = newValue;
        },
    },
    methods: {
        selectInovice(myInvoice) {
            this.localValue.uuid_sustitution = myInvoice.uuid;
        },
        async showInvoices() {
            this.invoiceHelper.visible = true;
            this.invoices = (await new Invoice(this.session).all()).filter(x => x.status_name == "TIMBRADO" 
            && x.receiver_rfc == this.customer
            && x.uuid.toUpperCase() != this.uuid.toUpperCase());
        },
        showAcuseXML() {
            this.$emit('acuse');
        },
        showAcusePDF() {
            this.$emit('acuse-pdf');
        },
        async cancel() {
            try {
                this.loading = true;
                let invoice = new Invoice();
                invoice = fillObject(invoice, this.localValue);
                let canceledInvoice = await invoice.cancel(invoice.cancelation_reason, invoice.uuid_sustitution);
                this.localValue.acuse = canceledInvoice.acuse;
                this.localValue.cancelation_status = canceledInvoice.cancelation_status;
                this.localValue.cancelation_reason = canceledInvoice.cancelation_reason;
                this.$emit('cancelled');
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
            
        } 
    },
    async mounted() {
        this.c_TipoRelacionCancelacion = await satCatalogo(17);
    }
}
</script>

<style>

</style>