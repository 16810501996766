<template>
    <Button v-tooltip="tooltip" :class="buttonClass">
        <img alt="logo" :src="iconImage" :style="myStyle" />
        <span v-if="text" style="margin-left: auto; margin-right: auto;">{{text}}</span>
    </Button>
</template>

<script>
import { FILE_ICONS } from "../../utilities/FILE_ICONS";
import { INVOICING_ICONS } from "../../utilities/INVOICING_ICONS";
import { ACCOUNTING_ICONS } from "../../utilities/ACCOUNTING_ICONS";

export default {
    props: {
        tooltip: {
            type: [String, null],
            default: null
        },
        icon: {
            type: String,
            default: "default"
        },
        pack: {
            type: String,
            default: "files"
        },
        text: {
            type: String,
            default: null
        },
        width: {
            type: String, 
            default: "1.5rem"
        },
        buttonClass: {
            type: String,
            default: null
        }
    },
    computed: {
        myStyle() {
            return {
                width: this.width
            }
        },
        iconImage() {
            return this.pack == "files" ? FILE_ICONS[this.icon] 
            : this.pack == "invoicing" ? INVOICING_ICONS[this.icon]
            : this.pack == "accounting" ? ACCOUNTING_ICONS[this.icon]
            : null;
        }
    }
}
</script>

<style>

</style>