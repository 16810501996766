import {
    Abstract
} from './Abstract';

import axios from "axios";

export class CreditNote extends Abstract {
    constructor(session) {
        super('credit_note/CreditNoteManager', session);
        this.version = '4.0';
        this.series = null;
        this.id_credit_note = null;
        this.date = new Date();
        this.payment_form = null;
        this.payment_coditions = null;
        this.customer = null;
        this.subtotal = 0.00;
        this.discount = 0.00;
        this.currency = 'MXN';
        this.exchange_rate = 1.00;
        this.voucher_type = "E";
        this.export = "01";
        this.payment_method = "PUE";
        this.expedition_place = null;
        this.confirmation = null;
        this.global_information_periodicity = null;
        this.global_information_months = null;
        this.global_information_anio = null;
        this.issuing_rfc = null;
        this.issuing_name = null;
        this.issuing_tax_regime = null;
        this.issuing_attribute = null;
        this.receiver_rfc = null;
        this.receiver_name = null;
        this.receiver_postal_code = null;
        this.receiver_tax_residence = null;
        this.receiver_num_reg_id_trib = null;
        this.receiver_tax_regime = null;
        this.receiver_credit_note_use = null;
        this.taxes_total_detained = 0.00;
        this.taxes_total_transferred = 0.00;
        this.taxes_total = 0.00;
        this.total = 0.00;
        this.credit_note_concepts = [];
        this.credit_note_related_invoices = [];
        this.timbrar = true;
        this.id_customer = null;
    }

    async list(params) { 

        let response = await axios.post(this.controller + '/data', params, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }

    async generate() {
        let response = await axios.post(this.controller + '/generate/40', this, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }

    async downloadXML() {
        if (this.id == null || this.id == 0 || this.id == "") 
            throw "Seleccione una factura para descargar el XML"
        let response = await axios({
                method: "get",
                url: this.controller  + "/xml/download/" + this.id,
                responseType: "blob"
            });
        var content = response.data;
        var blob = new Blob([content]);
        //var fileName = "ANUMA.xml";
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = this.xmLfilename;
        link.click();
        URL.revokeObjectURL(link.href);
    }

    async downloadPDF() {
        if (this.id == null || this.id == 0 || this.id == "") 
            throw "Seleccione una nota de credito para descargar el PDF"
        let response = await axios({
                method: "get",
                url: this.controller  + "/pdf/download/" + this.id,
                responseType: "blob"
            });
        var content = response.data;
        var blob = new Blob([content]);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = this.xmLfilename.split('.')[0] + ".pdf";
        link.click();
        URL.revokeObjectURL(link.href);
    }

    async concepts() {
        if (this.id == null || this.id == 0 || this.id == "") 
            throw "Seleccione una factura para recibir sus conceptos"
        let response = await axios.get(this.controller + '/concepts/' + this.id, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }

    async relatedInvoices(){
        if (this.id == null || this.id == 0 || this.id == "") 
        throw "Seleccione una factura para recibir sus documentos relacionados"
    let response = await axios.get(this.controller + '/relatedInvoices/' + this.id, {
        headers: {
            company: this.id_company,
            branch: this.id_branch,
            user: this.user
        }
    });
    return response.data;
    }

    async regenerate() {
        let response = await axios.put(this.controller + '/regenerate/40/' + this.id, this, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }
    
    async prodserv(params) {
        let query = "?";
        if (params) {
           Object.keys(params).forEach(prop => {
               if (params[prop] != null) {
                   query = query + prop + "=" + params[prop] + "&";
               }
           });
           query = query.substring(0, query.length - 1);
        }else {
            query = "";
        }
        
        let response = await axios.get(this.controller + "/prodserv" + query, {
            headers: {
                user: this.user
            }
        });
        return response.data;
    }

    async certificate() {
        if (this.id == null || this.id == 0 || this.id == "") 
            throw "Seleccione una factura para timbrar"
        let response = await axios.post(this.controller + '/timbrar/40/' + this.id, null, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }
}
