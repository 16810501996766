import {
    Abstract
} from './Abstract';

import axios from "axios";

export class ConsignmentNote extends Abstract {
    constructor(session) {
        super('consignment_note/ConsignmentNote', session);
        this.international_transport = null;
        this.transport_sat_code = null;
        this.goods_number = null;
        this.id_consignment_note_vehicle_control = null;
        this.entry_exit_good = null;
        this.party_figure = null;
        this.destination_country_sat_code = null;
        this.id_customer = null;
        this.vehicle_config_sat_code = null;
        this.via_input_output = null;
        this.consignment_note_origin_locations = [];
        this.consignment_note_destiny_locations = [];
        this.consignment_note_types_figures = [];
        this.consignment_note_goods = [];
        this.consignment_note_trailers = [];

        /*Campos adicionales */
        this.sub_trailer = null;
    }

    async trailers () {
        if (this.id == null || this.id == 0 || this.id == "") 
            throw "Seleccione una factura para recibir sus sub remolques"
        let response = await axios.get(this.controller + '/trailers/' + this.id, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }

    async origins () {
        if (this.id == null || this.id == 0 || this.id == "") 
            throw "Seleccione una factura para recibir sus ubicaciones de origen"
        let response = await axios.get(this.controller + '/origins/' + this.id, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }

    async destinations () {
        if (this.id == null || this.id == 0 || this.id == "") 
            throw "Seleccione una factura para recibir sus ubicaciones de destino"
        let response = await axios.get(this.controller + '/destinations/' + this.id, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }

    async typesFigures () {
        if (this.id == null || this.id == 0 || this.id == "") 
            throw "Seleccione una factura para recibir sus tipos de figura"
        let response = await axios.get(this.controller + '/typesFigures/' + this.id, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }

    async goods () {
        if (this.id == null || this.id == 0 || this.id == "") 
            throw "Seleccione una factura para recibir sus mercancias"
        let response = await axios.get(this.controller + '/goods/' + this.id, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }

    async prodservCP(params) {
        let query = "?";
        if (params) {
           Object.keys(params).forEach(prop => {
               if (params[prop] != null) {
                   query = query + prop + "=" + params[prop] + "&";
               }
           });
           query = query.substring(0, query.length - 1);
        }else {
            query = "";
        }
        
        let response = await axios.get(this.controller + "/prodservCP" + query, {
            headers: {
                user: this.user
            }
        });
        console.log(response.data);
        return response.data;

    }

    async fractiontariff(params) {

        let query = "?";
        if (params) {
           Object.keys(params).forEach(prop => {
               if (params[prop] != null) {
                   query = query + prop + "=" + params[prop] + "&";
               }
           });
           query = query.substring(0, query.length - 1);
        }else {
            query = "";
        }
        
        let response = await axios.get(this.controller + "/fractiontariff" + query, {
            headers: {
                user: this.user
            }
        });

        return response.data;
    }

    async materialpeligroso(params) {

        let query = "?";
        if (params) {
           Object.keys(params).forEach(prop => {
               if (params[prop] != null) {
                   query = query + prop + "=" + params[prop] + "&";
               }
           });
           query = query.substring(0, query.length - 1);
        }else {
            query = "";
        }
        
        let response = await axios.get(this.controller + "/materialpeligroso" + query, {
            headers: {
                user: this.user
            }
        });
        console.log(response.data);

        return response.data;
    }

    async tipoembalaje(params) {

        let query = "?";
        if (params) {
           Object.keys(params).forEach(prop => {
               if (params[prop] != null) {
                   query = query + prop + "=" + params[prop] + "&";
               }
           });
           query = query.substring(0, query.length - 1);
        }else {
            query = "";
        }
        
        let response = await axios.get(this.controller + "/tipoembalaje" + query, {
            headers: {
                user: this.user
            }
        });
        console.log(response.data);

        return response.data;
    }

    async post() {
        let response = await axios.post(this.controller + '/post', this, {
            headers: {
                company: this.id_company,
                branch: this.id_branch,
                user: this.user
            }
        });
        return response.data;
    }

}
