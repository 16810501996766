<template>
  <Fieldset :toggleable="true" :collapsed="false" legend="Conceptos">
    <Helper v-model="conceptHelper" header="Buscar Concepto" :headers="conceptHelperHeaders" :rows="dbConcepts" @selected="selectConceptHelper" />
    <Dialog v-model:visible="newConcept" :style="{ width: '70vw' }" :modal="true">
        <ConceptUI :isModal="true" />
    </Dialog>
    <Helper :style="{ width: '50%' }" @filter="findProdServ" :lazy="true" v-model="prodServHelper" header="Buscar Producto / Servicio SAT" :headers="prodServHeaders" :rows="prodServs" @selected="selectProdServ">
        <template v-slot:filters>
            <div class="col-6 field">
                <label for="">ID</label>
                <InputText v-model.trim="prodServHelper.id" />
            </div>
            <div class="col-6 field">
                <label for="">Descripcion</label>
                <InputText v-model.trim="prodServHelper.descripcion" />
            </div>
        </template>
    </Helper>
    <BasicFormToolbar v-if="version == 'invoice' && canceled == false" :actions="['search','add']" @add="newConcept = true" @search="searchConcept" />
    <TabView v-if="canceled == false" ref="tabview1">
        <TabPanel header="Informacion General">
            <div class="p-fluid formgrid grid">
                <FormInputNumber wrapperClass="field col-2" label="Cantidad" v-model="concept.quantity" :validation="'Favor de llenar el campo, no puede ser igual a 0'" :valid="validate_concept.validations.quantity " />
                <FormDropdownComplex @beforeShow="concept.unit_key = null" :valid="validate_concept.validations.unit_key"
                :labelInOption="'c_ClaveUnidad - Nombre'" :labelInValue="'c_ClaveUnidad - Nombre'" :filterFields="['c_ClaveUnidad', 'Nombre']"
                :wrapperClass="'field col-2'" label="Unidad" v-model="concept.unit_key" :options="c_ClaveUnidad_version" optionLabel="c_ClaveUnidad" optionValue="c_ClaveUnidad" />
                <FormInputText v-model="concept.unit_name" wrapperClass="field col-2" label="Nombre Unidad"/>
                <FormInputText v-model="concept.description" wrapperClass="field col-4" label="Descripcion" :valid="validate_concept.validations.description"/>
                <FormInputNumber wrapperClass="field col-2" label="P.U." :minFractionDigits="2" :maxFractionDigits="6" v-model="concept.unit_value" :validation="'Favor de llenar el campo, no puede ser igual a 0'" :valid="validate_concept.validations.unit_value" />
                <FormInputText :readonly="version == 'credit_note'"  wrapperClass="field col-2" label="ProdServ" v-model="concept.concept_key" :valid="validate_concept.validations.concept_key" :search="version == 'invoice'" @search="prodServHelper.visible = true"   />
                <FormDropdownComplex :valid="validate_concept.validations.tax_object" :labelInOption="'c_ObjetoImp - Descripcion'" :labelInValue="'c_ObjetoImp - Descripcion'" :wrapperClass="'field col-4'" label="Objeto Impuesto" v-model="concept.tax_object" :options="c_ObjetoImp" optionLabel="c_ObjetoImp" optionValue="c_ObjetoImp" />
                <FormInputNumber wrapperClass="field col-2" label="IVA Trasladado" :disabled="concept.tax_object == '01'" v-model="concept.iva" :min="0" :max="100"   :minFractionDigits="2" :maxFractionDigits="2" :mode="'decimal'" :prefix="'%'" />
                <FormInputNumber wrapperClass="field col-2" label="IEPS Trasladado" :disabled="concept.tax_object == '01'" v-model="concept.ieps" :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :mode="'decimal'" :prefix="'%'" />
                <FormInputNumber wrapperClass="field col-2" label="Descuento" v-model="concept.discount" :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :mode="'decimal'" :prefix="'%'" />
                <div class="field col">
                    <Button @click="saveConcepto" :class="concept.id_number ? 'p-button p-button-success p-button-inline' : 'p-button p-button-primary p-button-inline'" icon="pi pi-save" :label="concept.id_number ? 'Actualizar' : 'Guardar'" />
                </div>
            </div>
        </TabPanel>
        <TabPanel header="Retenciones">
            <div class="p-fluid formgrid grid">
                <FormInputNumber wrapperClass="field col-2" label="IVA Retenido" :disabled="concept.tax_object == '01'" v-model="concept.iva_detained" :min="0" :max="100"   :minFractionDigits="2" :maxFractionDigits="2" :mode="'decimal'" :prefix="'%'" />
                <FormInputNumber wrapperClass="field col-2" label="IEPS Retenido" :disabled="concept.tax_object == '01'" v-model="concept.ieps_detained" :min="0" :max="100" :minFractionDigits="2" :maxFractionDigits="2" :mode="'decimal'" :prefix="'%'" />
            </div>
        </TabPanel>
    </TabView>
    <div class="p-fluid formgrid grid">
        <div class="field col-12">
            <BasicDatatable :rowsFilter="false"
            :rowaction="!canceled"
            :rowedit="true"
            :rowdelete="true"
            fontsize="small"
            @edited="selectConcept"
            @deleted="confirmDelete"
            :headers="conceptsHeaders"
            :rows="concepts" />
        </div>
    </div>
</Fieldset>
</template>

<script>
import BasicDatatable from "../../../../components/general/BasicDatatable.vue";
import FormInputNumber from "../../../../components/general/FormInputNumber.vue";
import FormDropdownComplex from "../../../../components/general/FormDropdownComplex.vue";
import FormInputText from "../../../../components/general/FormInputText.vue";
import BasicFormToolbar from "../../../../components/general/BasicFormToolbar.vue";
import Helper from "../../../../components/general/HelperDialog.vue";
import { ErrorToast, HeaderGrid, Rule, validate } from '../../../../utilities/General';
import Session from "../../../../mixins/sessionMixin";
import { CreditNoteConcept } from '../../../../models/cfdi40/CreditNoteConcept';
import { InvoiceConcept } from '../../../../models/cfdi40/InvoiceConcept';
import { CreditNoteConceptTaxesTransferred } from '../../../../models/cfdi40/CreditNoteConceptTaxesTransferred';
import Decimal from 'decimal.js';
import { Concept } from '../../../../models/cfdi40/Concept';
import ConceptUI from "../../Catalogos/Concept.vue";
import { CreditNote } from '../../../../models/cfdi40/CreditNote';
import { InvoiceConceptTaxesTransferred } from '../../../../models/cfdi40/InvoiceConceptTaxesTransferred';

export default {
    mixins: [Session],
    components: {
        ConceptUI, Helper, BasicDatatable, FormInputNumber, FormDropdownComplex, FormInputText, BasicFormToolbar
    },
    props: {
        c_ClaveUnidad: {
            type: Array,
            default: () => []
        },
        c_ObjetoImp: {
            type: Array,
            default: () => []
        },
        concepts: {
            type: Array,
            default: () => []
        },
        version: {
            type: String,
            default: "invoice"
        },
        canceled: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        'concept.tax_object'(newValue) {
            if (newValue == '01') {
                this.concept.iva = 0;
                this.concept.ieps = 0;
                this.concept.iva_detained = 0;
                this.concept.ieps_detained = 0;
            } else {
                this.concept.iva = 16;
                this.concept.ieps = 0;
            }
        },
        'concept.unit_key'(newValue) {
            if (newValue) {
                this.concept.unit_name = this.version == "invoice" ? this.c_ClaveUnidad.find((x) => x.c_ClaveUnidad == newValue) ? this.c_ClaveUnidad.find((x) => x.c_ClaveUnidad == newValue).Nombre.toUpperCase() : '' : 'Actividad';
            }
        },
    },
    computed: {
        c_ClaveUnidad_version() {
            return this.version == "invoice" ? this.c_ClaveUnidad : this.c_ClaveUnidad.filter(x => x.c_ClaveUnidad == "ACT")
        }
    },
    created() {
        if (this.version == "invoice")
            this.concept = new InvoiceConcept(this.session);
        else if (this.version == "credit_note")
            this.concept = new CreditNoteConcept(this.session);
    },
    data() {
        return {
            prodServHelper: {
                visible: false,
                id: null,
                descripcion: null,
            },
            prodServHeaders: [new HeaderGrid('ID', 'id'), new HeaderGrid('Descripcion', 'descripcion')],
            prodServs: [],
            conceptHelperHeaders: [
                new HeaderGrid("Clave", "key_name"),
                new HeaderGrid("Descripcion", "description"),
                new HeaderGrid("SAT Unidad Medida", "sat_unit_key"),
                new HeaderGrid("Unidad Medida", "unit_name"),
                new HeaderGrid("Precio Unitario", "unit_value", { type: "decimal"}),
            ],
            deleteDialog: false,
            conceptDelete: null,
            loading: false,
            conceptsHeaders: [
                new HeaderGrid('#', 'id_number'),
                new HeaderGrid('Cantidad', 'quantity'),
                new HeaderGrid('Unidad', 'unit_name'),
                new HeaderGrid('Descripcion', 'description'),
                new HeaderGrid('P.U.', 'unit_value' , { type: 'currency'}),
                new HeaderGrid('Subtotal', 'subtotal' , { type: 'currency', function: 'SUM', formula: 'evaluate', expression: 'unit_value * quantity'}),
                new HeaderGrid('Descuento', 'discount' , { type: 'percentage'}) ,
                new HeaderGrid('IVA', 'iva' , { type: 'percentage'}) ,
                new HeaderGrid('IEPS', 'ieps' , { type: 'percentage'}) ,
                new HeaderGrid('Total', 'final_total' , { type: 'currency', function: 'SUM', formula: 'evaluate', expression: '((subtotal) - ((subtotal) * discount%)) + (((subtotal) - (subtotal) * discount%) * iva%) + (((subtotal) - (subtotal) * discount%) * ieps%)'}),
            ],
            concept: { },
            newConcept: false,
            rules_concept: [
                //* Version avanzada de reglas
                new Rule({ name: 'quantity', value: 'number', type: 'min', min: 0 }),
                new Rule({ name: 'concept_key' }),
                new Rule({ name: 'unit_key' }),
                new Rule({ name: 'description' }),
                new Rule({ name: 'unit_value', value: 'number', type: 'min', min: 0 }),
                new Rule({ name: 'tax_object' }),
            ],
            dbConcepts: [],
            conceptHelper: {
                visible: false,
            },
            validate_concept: {
                valid: false,
                validations: {
                    series: null,
                    payment_form: null,
                    currency: null,
                    exchange_rate: null,
                    voucher_type: null,
                    export: null,
                    payment_method: null,
                    expedition_place: null,
                    issuing_rfc: null,
                    issuing_name: null,
                    issuing_tax_regime: null,
                    receiver_rfc: null,
                    receiver_name: null,
                    receiver_postal_code: null,
                    receiver_tax_regime: null,
                    receiver_credit_note_use: null,
                    receiver_email: null,
                },
            },
        }
    },
    methods: {
        selectProdServ(prodServ) {
            this.concept.concept_key = prodServ.id;
        },
        async findProdServ() {
            try {
                this.loading = true;
                this.prodServs = await new CreditNote(this.session).prodserv({
                    id: this.prodServHelper.id,
                    descripcion: this.prodServHelper.descripcion,
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        saveConcepto() {
            if (this.version == "invoice")
                this.saveConceptoInvoice();
            else if (this.version == "credit_note")
                this.saveConceptoCreditNote();
        },
        saveConceptoInvoice() {
        try {
                this.validate_concept = validate(this.concept, this.rules_concept);
                if (!this.validate_concept.valid) throw 'Favor de validar los campos';
                let concept = {
                    ...this.concept,
                };
                concept.invoice_concept_taxes_transferreds = [];
                concept.invoice_concept_taxes_detaineds = [];
                //Si ya tiene asignado actualiza
                let update = concept.id_number;
                concept.id_number = concept.id_number ? concept.id_number : (this.concepts.length + 1).toString();
                if (concept.tax_object != '01') {
                    if (concept.iva && concept.iva > 0) {
                        let iva = new InvoiceConceptTaxesTransferred();
                        iva.tax = '002';
                        iva.factor_type = '0';
                        var rate_or_fee = new Decimal(concept.iva);
                        iva.rate_or_fee = rate_or_fee.dividedBy(100);
                        if (!concept.invoice_concept_taxes_transferreds) concept.invoice_concept_taxes_transferreds = [];
                        concept.invoice_concept_taxes_transferreds.push(iva);
                    }
                    if (concept.ieps && concept.ieps > 0) {
                        let ieps = new InvoiceConceptTaxesTransferred();
                        //* IEPS
                        ieps.tax = '003';
                        ieps.factor_type = '0';
                        var rate_or_fee_ieps = new Decimal(concept.ieps);
                        ieps.rate_or_fee = rate_or_fee_ieps.dividedBy(100);
                        if (!concept.invoice_concept_taxes_transferreds) concept.invoice_concept_taxes_transferreds = [];
                        concept.invoice_concept_taxes_transferreds.push(ieps);
                    }
                    if (concept.iva_detained && concept.iva_detained > 0) {
                        let iva_detained = new InvoiceConceptTaxesTransferred();
                        iva_detained.tax = '002';
                        iva_detained.factor_type = '0';
                        var rate_or_fee_iva_detained = new Decimal(concept.iva_detained);
                        iva_detained.rate_or_fee = rate_or_fee_iva_detained.dividedBy(100);
                        if (!concept.invoice_concept_taxes_detaineds) concept.invoice_concept_taxes_detaineds = [];
                        concept.invoice_concept_taxes_detaineds.push(iva_detained);
                    }
                    if (concept.ieps_detained && concept.ieps_detained > 0) {
                        let ieps_detained = new InvoiceConceptTaxesTransferred();
                        //* IEPS
                        ieps_detained.tax = '003';
                        ieps_detained.factor_type = '0';
                        var rate_or_fee_ieps_detained = new Decimal(concept.ieps_detained);
                        ieps_detained.rate_or_fee = rate_or_fee_ieps_detained.dividedBy(100);
                        if (!concept.invoice_concept_taxes_detaineds) concept.invoice_concept_taxes_detaineds = [];
                        concept.invoice_concept_taxes_detaineds.push(ieps_detained);
                    }
                }

                if(update)
                    this.$emit("update", concept)
                else
                    this.$emit("save", concept)
                this.concept = new InvoiceConcept(this.session);
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            }
        },
        saveConceptoCreditNote() {
            try {
                if (this.concepts.length > 0)
                    throw "No se puede tener mas de un 1 concepto en la nota de credito, favor de actualizar el ya existente"

                this.validate_concept = validate(this.concept, this.rules_concept);
                if (!this.validate_concept.valid) throw 'Favor de validar los campos';
                let concept = {
                    ...this.concept,
                };
                concept.credit_note_concept_taxes_transferreds = [];
                concept.credit_note_concept_taxes_detaineds = [];
                //Si ya tiene asignado actualiza
                let update = concept.id_number;
                concept.id_number = concept.id_number ? concept.id_number : (this.concepts.length + 1).toString();
                if (concept.tax_object != '01') {
                    if (concept.iva && concept.iva > 0) {
                        let iva = new CreditNoteConceptTaxesTransferred();
                        iva.tax = '002';
                        iva.factor_type = '0';
                        var rate_or_fee = new Decimal(concept.iva);
                        iva.rate_or_fee = rate_or_fee.dividedBy(100);
                        if (!concept.credit_note_concept_taxes_transferreds) concept.credit_note_concept_taxes_transferreds = [];
                        concept.credit_note_concept_taxes_transferreds.push(iva);
                    }
                    if (concept.ieps && concept.ieps > 0) {
                        let ieps = new CreditNoteConceptTaxesTransferred();
                        //* IEPS
                        ieps.tax = '003';
                        ieps.factor_type = '0';
                        var rate_or_fee_ieps = new Decimal(concept.ieps);
                        ieps.rate_or_fee = rate_or_fee_ieps.dividedBy(100);
                        if (!concept.credit_note_concept_taxes_transferreds) concept.credit_note_concept_taxes_transferreds = [];
                        concept.credit_note_concept_taxes_transferreds.push(ieps);
                    }
                    if (concept.iva_detained && concept.iva_detained > 0) {
                        let iva_detained = new CreditNoteConceptTaxesTransferred();
                        iva_detained.tax = '002';
                        iva_detained.factor_type = '0';
                        var rate_or_fee_iva_detained = new Decimal(concept.iva_detained);
                        iva_detained.rate_or_fee = rate_or_fee_iva_detained.dividedBy(100);
                        if (!concept.credit_note_concept_taxes_detaineds) concept.credit_note_concept_taxes_detaineds = [];
                        concept.credit_note_concept_taxes_detaineds.push(iva_detained);
                    }
                    if (concept.ieps_detained && concept.ieps_detained > 0) {
                        let ieps_detained = new CreditNoteConceptTaxesTransferred();
                        //* IEPS
                        ieps_detained.tax = '003';
                        ieps_detained.factor_type = '0';
                        var rate_or_fee_ieps_detained = new Decimal(concept.ieps_detained);
                        ieps_detained.rate_or_fee = rate_or_fee_ieps_detained.dividedBy(100);
                        if (!concept.credit_note_concept_taxes_detaineds) concept.credit_note_concept_taxes_detaineds = [];
                        concept.credit_note_concept_taxes_detaineds.push(ieps_detained);
                    }
                }

                if(update)
                    this.$emit("update", concept)
                else
                    this.$emit("save", concept)
                this.concept = new CreditNoteConcept(this.session);
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            }
        },
        async searchConcept() {
            try {
                this.loading = true;
                this.dbConcepts = await new Concept(this.session).all();
                this.conceptHelper.visible = true;
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        selectConceptHelper(concept) {
            this.concept.unit_name = concept.unit_name;
            this.concept.concept_key = concept.sat_key;
            this.concept.id_concept = concept.id;
            this.concept.description = concept.description;
            this.concept.tax_object = concept.tax_object;
            this.concept.unit_key = concept.sat_unit_key;
            this.concept.unit_value = concept.unit_value;
            this.concept.iva = concept.discount;
            this.concept.iva = concept.iva_transferred;
            this.concept.ieps = concept.ieps_transferred;
            this.conceptHelper.visible = false;
        },
        selectConcept(concept) {
            this.concept = concept;
        },
        confirmDelete(concept) {
            this.$emit('delete', concept)
        },
    }
}
</script>

<style>
    .p-button-inline {
        margin-top: 1.7rem;
    }
</style>