<template>
    <div class="grid crud-demo">
        <Dialog v-model:visible="editInvoice" :closable="true" :style="{ width: '75vw' }" :modal="true">
            <InvoiceUI :id="editCard.id" @updated="list" @deleted="list" />
        </Dialog>
        <Dialog v-model:visible="editPolicy.visible" :closable="true" :style="{ width: '75vw' }" :modal="true">
            <PolicyUI :id="editPolicy.id" />
        </Dialog>
        <Helper v-model="customerHelper" header="Buscar Cliente" :headers="customersHeaders" :rows="customers" @selected="selectCustomer" />

        <Loader v-model="loading" />
        <div class="col-12">
            <div class="card">
                <Toast />
                <Panel header="Administrador de Facturas">
                    <BasicFormToolbar :actions="['list','new']" @new="newEntity"  @list="list" />
                    <Fieldset :toggleable="true" legend="Parametros">
                        <div class="p-fluid formgrid grid">
                            <FormCalendar label="Fecha Inicial" :wrapperClass="'field col-4'" v-model="entity.initial_date" />
                            <FormCalendar label="Fecha Final" :wrapperClass="'field col-4'" v-model="entity.final_date" />
                            <FormInputText label="Cliente" :wrapperClass="'field col-4'" :readonly="true" v-model="entity.name" :clear="true" :search="true" @clear="clearCustomer" @search="customerHelper.visible = true" />
                        </div>
                    </Fieldset>
                    <br />
                    <Fieldset :toggleable="true" legend="Resultados">
                        <BasicDatatable :files="['xml','pdf']" :exportFilename="'ADMINISTRADOR_FACTURAS'"  @view="showInvoice" :rowaction="true" :rowview="true" :rows="entities" :headers="headers"
                        :globalFilterFields="['series', 'id_invoice', 'status', 'receiver_name']" @chip-clicked-key_name_policy="selectPoliza" :actionRowWidth="100" @xml="downloadXML" @pdf="downloadPDF">
                            <template #custom-row-button="{ slotScope }">
                                <ImageButton @click="generarPoliza(slotScope)" tooltip="Generar poliza" buttonClass="p-button-icon-only p-button-rounded p-mr-2" pack="accounting" icon="poliza" />
                            </template>
                        </BasicDatatable>
                    </Fieldset>
                </Panel>
            </div>
        </div>
    </div>
</template>
<script>
import { Invoice } from '../../../models/cfdi40/Invoice';
import Session from '../../../mixins/sessionMixin';
import { Rule, ErrorToast, HeaderGrid, Toast, fillObject } from '../../../utilities/General';
import Loader from '../../../components/general/Loader.vue';
import BasicDatatable from '../../../components/general/BasicDatatable.vue';
import FormInputText from '../../../components/general/FormInputText.vue';
import ImageButton from '../../../components/general/ImageButton.vue'
import FormCalendar from '../../../components/general/FormCalendar.vue';
import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue';
import Helper from "../../../components/general/HelperDialog.vue";

import { Customer } from '../../../models/comercial/Customer';
import InvoiceUI from './Invoice.vue';
import PolicyUI from '../../Contabilidad/Aplicaciones/AccountingPolicy.vue';

import { AccountingPolicyConfiguration } from '../../../models/contabilidad/AccountingPolicyConfiguration';
export default {
    mixins: [Session],
    data() {
        return {
            editInvoice: false,
            editPolicy: {
                visible: false,
                id: null
            },
            editCard: new Invoice(this.session),
            entity: {
                id_customer: null,
                initial_date: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                final_date: new Date(),
                name: "TODOS LOS CLIENTES"
            },
            headers: [
                new HeaderGrid('Factura', 'concat_invoice', {formula: 'concat', expression: 'series + {-} + id_invoice'}),
                new HeaderGrid('Timbre','is_certificated', {type: "boolean"}),
                new HeaderGrid('Poliza','key_name_policy', {component: "chip", component_options: { icon: "pi pi-window-maximize" }}),
                new HeaderGrid('RFC','receiver_rfc'),
                new HeaderGrid('Cliente','receiver_name'),
                new HeaderGrid('Fecha','date', { type: "date" }),
                new HeaderGrid('SubTotal','subtotal', { type: "currency", function: "SUM" }),
                new HeaderGrid('Impuestos','taxes_total_transferred', { type: "currency", function: "SUM" }),
                new HeaderGrid('Total','total', { type: "currency", function: "SUM" })
            ],
            entities: [],
            tags: [],
            customers: [],
            customersHeaders: [
                new HeaderGrid('Nombre', 'name'),
                new HeaderGrid('Apellidos', 'last_name'),
                new HeaderGrid('RFC', 'rfc'),
                new HeaderGrid('Direccion', 'address'),
                new HeaderGrid('CP', 'postal_code'),
                new HeaderGrid('Pais', 'country'),
                new HeaderGrid('Estado', 'state'),
                new HeaderGrid('Municipio', 'municipality'),
                new HeaderGrid('Colonia', 'suburb'),
                new HeaderGrid('Regimen Fiscal', 'tax_regime'),
            ],
            filters: null,
            loading: false,
            loadingInputs: false,
            rules: [new Rule({ name: 'initial_date' }), new Rule({ name: 'final_date' })],
            validate: {
                valid: false,
                validations: {
                    initial_date: null,
                    final_date: null,
                },
            },
            customerHelper: {
                visible: false,
            },
        };
    },
    components: {PolicyUI,ImageButton, FormInputText , Helper, BasicFormToolbar, Loader, InvoiceUI, BasicDatatable, FormCalendar },
    async mounted() {
        await this.refresh();
    },
    methods: {
         async downloadXML(payload) {
            this.loading = true;
            try {
                let invoice = new Invoice(this.session);
                invoice.id = payload.id;
                invoice = fillObject(invoice, await invoice.retrieve());
                await invoice.downloadXML();
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async downloadPDF(payload) {
            this.loading = true;
            try {
                let invoice = new Invoice(this.session);
                invoice.id = payload.id;
                invoice = fillObject(invoice, await invoice.retrieve());
                await invoice.downloadPDF();
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        selectPoliza(invoice) {
            this.editPolicy.id = invoice.id_accounting_policy;
            this.editPolicy.visible = true;
        },
        async generarPoliza(invoice) {
            this.loading = true;
            try {
                let policy = await new AccountingPolicyConfiguration(this.session).generateInvoicePolicy(invoice.id);
                let index = this.entities.findIndex(x => x.id == invoice.id);
                this.entities[index].id_accounting_policy = policy.id;
                let month = new Date(policy.date).getMonth() + 1;
                let month_key = month < 10 ? "0" + month : month.toString();
                let key_name_policy = new Date(policy.date).getFullYear().toString() + "|" + month_key + "|" + policy.key_type + "|" + policy.id_number;
                this.entities[index].key_name_policy = key_name_policy;
                this.$toast.add(new Toast({summary: "Factura", detail: "Poliza generada con exito"}));
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        showInvoice(invoice) {
            this.editCard.id = invoice.id;
            this.editInvoice = true;
        },
        clearCustomer() {
            this.entity.name = "TODOS LOS CLIENTES";
            this.entity.id_customer = null;
        },
        getStyleChip(color) {
            return {
                background: '#' + color,
            };
        },
        async list() {
            this.loading = true;
            this.editInvoice = false;
            try {
                this.entities = await new Invoice().list({
                    customer: this.entity.id_customer,
                    initial_date: this.entity.initial_date,
                    final_date: this.entity.final_date,
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        selectCustomer(customer) {
            this.entity.name = customer.name;
            this.entity.id_customer = customer.id;
        },
        newEntity() {
            this.entity = {
                id_customer: null,
                initial_date: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                final_date: new Date(),
                name: "TODOS LOS CLIENTES"
            };
        },
        async refresh() {
            this.loading = true;
            try {
                this.customers = await new Customer(this.session).all();
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
<style scoped lang="scss">
.table-header {
    display: flex;
    justify-content: space-between;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-toolbar) {
        flex-wrap: wrap;

        .p-button {
            margin-bottom: 0.25rem;
        }
    }
    .p-chip.custom-chip {
        background: var(--primary-color);
        color: var(--primary-color-text);
    }
    .p-chip.tag-chip {
        background: #2c9663;
        color: var(--primary-color-text);
    }
}
</style>