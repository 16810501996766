<template>
  <Dialog
    :closeOnEscape="true"
    :closable="true"
    :modal="true"
    header="Enviar E-mail"
    :style="{ width: '75%'}"
    v-model:visible="localValue.visible"
  >
    <div class="p-fluid formgrid grid">
      <Loader v-model="loading" />
      <FormInputText v-model="emailForm.to" :wrapperClass="'field col-12'" :label="'Para'" />
      <FormInputText v-model="emailForm.cc" :wrapperClass="'field col-6'" :label="'CC'" />
      <FormInputText v-model="emailForm.cco" :wrapperClass="'field col-6'" :label="'CCO'" />
      <FormInputText v-model="emailForm.title" :wrapperClass="'field col-12'" :label="'Asunto'" />
      <div class="field col-12">
          <Editor v-model="emailForm.body" editorStyle="height: 320px"/>
      </div>
      <div class="col-12">
        <Button label="Enviar" @click="send" />
      </div>
    </div>
  </Dialog>
</template>

<script>
import Session from "../../mixins/sessionMixin";
import { EmailConfiguration } from '../../models/general/EmailConfiguration';
import { fillObject } from '../../utilities/General';
import FormInputText from "../general/FormInputText.vue";
import Loader from "../general/Loader.vue";

export default {
  mixins: [Session],
  components: {
    FormInputText, Loader
  },
  props: {
    document: null,
    title: null,
    key_name: null,
    to: null,
    modelValue: {
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      localValue: this.modelValue,
      emailConfiguration: new EmailConfiguration(),
      emailForm: {
        configuration: null,
        to: null,
        cc: null,
        cco: null,
        title: "",
        body: null
      }
    };
  },
  methods: {
    async send() {
      try {
        this.loading = true;
        this.emailForm.configuration = this.emailConfiguration.key_name;
        await this.emailConfiguration.send(this.emailForm, this.document);
        this.$emit('sended');
      } catch (error) {
        this.$emit('error', error);
      } finally {
        this.loading = false;
      }
    }
  },
  watch: {
    async 'localValue.visible'(newValue) {
      if (newValue) {
        this.emailForm.to = this.to;
       try {
         let _emailConfiguration = new EmailConfiguration(this.session);
        _emailConfiguration.key_name = this.key_name;
        this.emailConfiguration =  fillObject(this.emailConfiguration, await _emailConfiguration.retrieveByKeyName());
        this.emailForm.title = this.emailConfiguration.sender_title + " " + this.title;
        this.emailForm.body = "<p>Buen dia</p> <p>Anexamos sus documentos correspondientes en el siguiente correo</p>"
        //*AQUI SE DEBERIA DE ARMAR ALGO CHIDO
       } catch (error) {
        this.$emit('error', error);
       }
      }
    },
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    modelValue(newValue) {
      this.localValue = newValue;
    },
  },
};
</script>

<style></style>
