import {
    Abstract
} from './Abstract';

export class CreditNoteConcept extends Abstract {
    constructor(session) {
        super('credit_note/CreditNoteManager', session);
        this.id_credit_note = null;
        this.concept_key = "84111506";
        this.id_number = null;
        this.quantity = 1;
        this.unit_key = "ACT";
        this.unit_name = "Actividad";
        this.description = "NC por devolucion";
        this.unit_value = 0;
        this.amount = 0;
        this.discount = 0;
        this.tax_object = "02";
        this.third_party_account_rfc = null;
        this.third_party_account_name = null;
        this.third_party_tax_regime = null;
        this.third_party_tax_residence = null;
        this.iva = 16;
        this.ieps = 0;
        this.iva_detained = 0;
        this.ieps_detained = 0;
        this.id_concept = null;
        this.credit_note_concept_taxes_transferreds = [];
        this.credit_note_concept_taxes_detaineds = [];
    }
}
