<template>
   <Fieldset :toggleable="true" legend="Informacion Emisor">
        <TabView>
            <TabPanel header="Informacion General">
                <div class="p-fluid formgrid grid">
                    <FormInputText :readonly="true" v-model="myEntity.issuing_rfc" wrapperClass="field col-12" label="RFC" />
                    <FormInputText :readonly="true" v-model="myEntity.issuing_name" wrapperClass="field col-12" label="Nombre / Razon Social" />
                    <FormDropdownComplex :disabled="true" :labelInOption="'c_RegimenFiscal - Descripcion'" :labelInValue="'c_RegimenFiscal - Descripcion'" :wrapperClass="'field col-12'" label="Regimen Fiscal" v-model="myEntity.issuing_tax_regime" :options="c_RegimenFiscal" optionLabel="c_RegimenFiscal" optionValue="c_RegimenFiscal" />
                </div>
            </TabPanel>
            <TabPanel header="Certificado">
                <div class="p-fluid formgrid grid">
                    <FormInputText :readonly="true" v-model="myCertificate.cer_number" wrapperClass="field col-12" label="# Certificado" />
                    <FormInputText :readonly="true" v-model="myCertificate.cer_name" wrapperClass="field col-12" label="Nombre Certificado" />
                    <FormCalendar :disabled="true" v-model="myCertificate.initial_date" wrapperClass="field col-6" label="Valido desde"/>
                    <FormCalendar :disabled="true" v-model="myCertificate.final_date" wrapperClass="field col-6" label="Valido hasta" />
                </div>
            </TabPanel>
        </TabView> 
    </Fieldset>
</template>

<script>
import FormInputText from "../../../../components/general/FormInputText.vue";
import FormCalendar from "../../../../components/general/FormCalendar.vue";
import FormDropdownComplex from "../../../../components/general/FormDropdownComplex.vue";


export default {
    components: {FormInputText, FormCalendar, FormDropdownComplex},
    props: {
        c_RegimenFiscal: {
            type: Array,
            default: () => []
        },
        entity: {
            type: Object,
            default: () => {}
        },
        certificate: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            myEntity: this.entity,
            myCertificate: this.certificate
        }
    },
    watch: {
        entity(newValue) {
            this.myEntity = newValue;
        },
        certificate(newValue) {
            this.myCertificate = newValue;
        }
    }
}
</script>

<style>

</style>